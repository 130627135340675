import pepperoni from '../vectors/ingredients/pepperoni-icon.svg';
import ham from '../vectors/ingredients/ham-icon.svg';
import bacon from '../vectors/ingredients/bacon-icon.svg';
import sausage from '../vectors/ingredients/sausage-icon.svg';
import beef from '../vectors/ingredients/beef-icon.svg';
import chicken from '../vectors/ingredients/chicken-icon.svg';

const UI_TEXT = {
  "main": {
    "page_title": "Pizza scoring report",
    "header": "Daily review",
    "greetings": "Hello",
    "greetings2": "Welcome back to Kwali.",
    "dashboard": "Dashboard",
    "analytics": "Analytics",
    "analytics_title": "Visit Analytics page for comprehensive statistics about your historical data.",
    "load_more": "Show more snapshots"
  },
  "search": {
    "venue_label": "-- Venue --",
    "error": "Error during retrieval of daily results",
    "no_venue": "Please choose a venue!",
    "wrong_date": "Invalid date!",
    "button": "Search"
  },
  "sort": {
    "label": "Sort by:",
    "title": "Repeat selection to reverse sorting order",
    "default": "Default (time)",
    "score": "Score",
    "specialty": "Specialty"
  },
  "filter": {
    "vids_details": "Video details",
    "vids_no_pizza": "Videos with no pizzas",
    "score_label": "-- Scored --",
    "score_yes": "Yes",
    "score_no": "No",
    "specialty_undefined": "-- NO DATA --",
    "specialty_checkAll_text": "All",
    "specialty_checkAll_title": "Select all",
    "specialty_uncheckAll_text": "None",
    "specialty_uncheckAll_title": "Un-select all",
    "specialty_noneSelectedText": "-- Specialty --",
    "specialty_selectedText": "specialties",
    "from": "-- From --",
    "to": "-- Until --",
    "feedback_label": "-- Feedback --",
    "feedback_yes": "Yes",
    "feedback_no": "No"
  },
  "feedback": {
    "explanation": "User feedback",
    "button": "Give feedback",
    "error": "Error saving data",
    "dialog_title": "User Feedback",
    "message": "Thank you for helping us improve by correcting the information given by the system:",
    "specialty": "Specialty",
    "score": "Score",
    "extra": "Extra",
    "extra_placeholder": "Here you can add your remarks about the image or the scoring...",
    "send": "Send",
    "product": "Product"
  },
  "censor": {
    "button": "HIDE PIZZA!",
    "label": "< HIDDEN >",
    "error": "Error saving data",
    "dialog_title": "Hide featured pizza",
    "message": "Are you sure you want to hide this result?",
    "yes": "Yes",
    "no": "No",
    "send": "Send"
  },
  "summary": {
    "title": "Summary",
    "no_data": "No data available for specified venue and date.",
    "pizzas_total": "Number of Pizzas",
    "pizzas_evaluated": "Scored Pizzas",
    "score_min": "Worst score",
    "score_max": "Best score",
    "score_avg": "Average score",
    "plot_button": "Summary graph",
    "plot_dialog_title": "Score histogram",
    "plot_score": "Score"
  },
  "gt_simple": {
    "button": "Review Simple GT"
  },
  "video": {
    "no_file": "Video file not available",
    "next_pizza": "Next pizza ->",
    "no_pizzas": "No pizzas found in this interval.",
    "no_results": "No results found for this interval."
  },
  "pipeline_plot": {
    "button": "-> Detection plot",
    "no_file": "Plot file was not found.\nThere might not have been pizza observations for this video.",
    "no_path": "File path not available."
  },
  "image": {
    "enlarge": "Enlarge snapshot"
  },
  "ingredients": {
    "to_review": "Review ingredients"
  },
  "specialty": {
    "explanation": "Specialty detected automatically by Kwali"
  },
  "score": {
    "explanation": "Click for details",
    "explanation_2": "(ingredients score taken from modified grid)",
    "pj_row_explanation": "per slice maximum:",
    "soft_row_explanation": "maximum:",
    "dialog_title": "Detailed Score",
    "table_header": "Official grid",
    "tables_total": "Total: ",
    "table_total_explanation": "max: 10 (1.2 base score)",
    "soft_table_header": "Modified grid",
    "soft_total_explanation": "max: 10 (0.4 base score)",
    "mixed_table_header": "Scoring grid",
    "mixed_total_explanation": "max: 10 (1.2 base score)",
    "base_points": "Base score"
  },
  "tracking": {
    "button": "Review detections"
  },
  "crust": {
    "label": "Crust type",
    "select": "-- Crust type --",
    "thin": "thin",
    "original": "original",
    "tradicional": "Original",
    "delgada": "Thin",
    'stuffed_crust_cheese_edge': 'Stuffed Crust – Cheese edge',
    'pan': 'Pan',
    'cheese_pops_cheese_pops_pepperoni': 'Cheese Pops / Cheese Pops Pepperoni',
    'crunchy_thin': 'Crunchy thin'
  },
  "snap_quality": {
    "label": "Snapshot quality",
    "select": "-- Snapshot quality --",
    "good": "good",
    "bad": "bad",
    "ok": "OK",
    "blurred": "blurred",
    "occluded": "occluded"
  },
  "specialty_check": {
    "label": "Specialty ingredients",
    "select": "-- Specialty ingredients --",
    "correct": "ok",
    "incorrect": "error"
  },
  "specialties": {
    "es": {
      "half": "2 Mitades",
      "custom": "Custom",

      //                        --- Global --->
      "cheese": "Queso",
      "hawaiian": "Hawaiian",
      "meats": "Meats",
      "napolitana": "Napolitana",
      "pepperoni": "Pepperoni",

      //                        --- PapaJohn's --->
      "super_pepperoni": "Super Pepperoni",
      "pepperoni_cups": "Pepperoni Cups",
      "vegetariana": "Vegetariana",
      "italiana": "Italiana",
      "todas_las_carnes": "Todas las Carnes",
      "the_works": "The Works",
      "pulled_pork": "Pulled Pork BBQ",
      "meatball_pepperoni": "Meatball Pepperoni",
      "pollo_bbq": "Pollo BBQ",
      "espinacas_crema": "Espinacas a la Crema",
      "meatball_bbq": "Meatball BBQ",
      "hawaiana": "Hawaiana",
      //"napolitana": "Napolitana",
      "canadian_bacon": "Canadian Bacon",
      "chicken_garlic": "Chicken Garlic",
      "napolitana_cherry": "Napolitana Cherry",
      "the_vegan_royal": "The Vegan Royal",
      "the_vegan_queen": "The Vegan Queen",
      "sweet_blue_cheese": "Sweet Blue Cheese",
      "pollo_caprese": "Pollo Caprese",
      "philly_cheesesteak": "Philly Cheesesteak",
      "napolitana_caprese": "Napolitana Caprese",
      "bbq_chicken_bacon": "BBQ Chicken Bacon",
      "pepp_sausage_6_cheese": "Pepperoni, Sausage and Six Cheese",
      //"meats": "Meats",
      "tuscan_6_cheese": "Tuscan Six Cheese",
      "garden_fresh": "Garden Fresh",
      "spinach_tomato_alfredo": "Fresh Spinach & Tomato Alfredo",
      "shaq_a_roni": "Shaq-a-Roni",
      "hawaiian_bbq_chicken": "Hawaiian BBQ Chicken",
      "zesty_italian_trio": "Zesty Italian Trio",
      "super_hawaiian": "Super Hawaiian",
      "works": "Works",
      "ultimate_pepperoni": "Ultimate Pepperoni",
      "fiery_buffalo_chicken": "Fiery Buffalo Chicken",
      "extra_cheesy_alfredo": "Extra Cheesy Alfredo",
      //"cheese": "Queso",
      //"pepperoni": "Pepperoni",
      "double_cheeseburger": "Double Cheeseburger",

      //                        --- PapaJohn's UAE --->
      'all_the_meats': 'All the Meats',
      'chicken_super_papa': 'Chicken Super Papa',
      //'hawaiian': 'Hawaiian',
      //'pepperoni': 'Pepperoni',
      'super_papa': 'Super Papa',
      'chicken_bbq': 'Chicken BBQ',
      'garden_special': 'Garden Special',
      'margherita': 'Margherita',
      'spicy_chicken_ranch': 'Spicy Chicken Ranch',

      //                        --- PizzaHut --->
      'pepperoni_lovers': 'Pepperoni Lovers',
      'doble_pepperoni': 'Doble Pepperoni',
      //'pepperoni': 'Pepperoni', // (aka 'Pepperoni Nacional') also in PapaJohn's
      'suprema': 'Suprema',
      'super_suprema': 'Super Suprema',
      'meat_lovers': 'Meat Lovers',
      'veggie_lovers': 'Veggie Lovers',
      //'hawaiana': 'Hawaiana', // also in PapaJohn's
      'veggie': 'Veggie',
      //'napolitana': 'Napolitana', // also in PapaJohn's
      'meaty_bbq': 'Meaty BBQ',
      'espanola': 'Española',
      'espanolisima': 'Españolisima',
      'meaty': 'Meaty',
      //'italiana': 'Italiana', // also in PapaJohn's
      'american_meat': 'American Meat',
      'milano': 'Milano',
      'tejana_bbq': 'Tejana BBQ',
      '4_estaciones': '4 Estaciones',
      'bbq_meat_cheese_pops': 'BBQ Meat & Cheese Pops',

      //                        --- Dominos --->
      //'pepperoni': 'Pepperoni', //already defined
      'meatzzza': 'Meatzza',
      'meatzza': 'Meatzza',
      'deluxe': 'Deluxe',
      'extravaganzza': 'ExtravaganZZa',
      'cheeseburger': 'Cheeseburger',
      'philly_cheese_steak': 'Philly cheese steak',
      'chicken_taco': 'Chicken Taco',
      'buffalo_chicken': 'Buffalo chicken',
      'memphis_bbq_chicken': 'Memphis BBQ chicken',
      'cali_chicken_bacon_ranch': 'Cali chicken bacon ranch',
      'spinach_n_feta': 'Spinach & Feta',
      'pacific_veggie': 'Pacific veggie',
      'wisconsin_6_cheese': 'Wisconsin 6 cheese',
      'honolulu_hawaiian': 'Honolulu hawaiian',
      'extra_cheese': 'Extra Cheese',

      //                        --- Melt --->
      //"custom": "Custom",
      //'pepperoni': 'Pepperoni', //already defined
      'double_pepperoni': 'Doble Pepperoni',
      'classic': 'Classic',
      'pesto_margarita': 'Pesto Margarita',

      //                        --- LittleCaesar_MX --->
      //"custom": "Custom",
      //"cheese": "Queso",
      //'hawaiian': 'Hawaiian',
      //'pepperoni': 'Pepperoni',
      'meat': 'Meat',

      //                        --- CloverLeaf --->
      // same as LittleCaesar_MX

      //                        --- PizzaHut_TZ --->
      //"custom": "Custom",
      //'pepperoni': 'Pepperoni',
      //"cheese": "Queso",
      'bbq_chicken_and_sausage': 'BBQ Chicken and Sausage',
      'sweet_chili_chicken': 'Sweet Chili Chicken',

      //                        --- PizzaHut_BR --->
      // same as Global
    },
    "en": {
      "half": "2 Halves",
      "custom": "Custom",

      //                        --- Global --->
      "cheese": "Cheese",
      "hawaiian": "Hawaiian",
      "meats": "Meats",
      "napolitana": "Napolitan",
      "pepperoni": "Pepperoni",

      //                        --- PapaJohn's --->
      "super_pepperoni": "Super Pepperoni",
      "pepperoni_cups": "Pepperoni Cups",
      "vegetariana": "Vegetarian",
      "italiana": "Italian",
      "todas_las_carnes": "Meats",
      "the_works": "Works",
      "pulled_pork": "Pulled Pork BBQ",
      "meatball_pepperoni": "Meatball Pepperoni",
      "pollo_bbq": "Chicken BBQ",
      "espinacas_crema": "Spinach with Cream",
      "meatball_bbq": "Meatball BBQ",
      "hawaiana": "Hawaiian",
      //"napolitana": "Napolitan",
      "canadian_bacon": "Canadian Bacon",
      "chicken_garlic": "Chicken Garlic",
      "napolitana_cherry": "Cherry Napolitan",
      "the_vegan_royal": "The Vegan Royal",
      "the_vegan_queen": "The Vegan Queen",
      "sweet_blue_cheese": "Sweet Blue Cheese",
      "pollo_caprese": "Caprese Chicken",
      "philly_cheesesteak": "Philly Cheesesteak",
      "napolitana_caprese": "Caprese Napolitan",
      "bbq_chicken_bacon": "BBQ Chicken Bacon",
      "pepp_sausage_6_cheese": "Pepperoni, Sausage and Six Cheese",
      //"meats": "Meats",
      "tuscan_6_cheese": "Tuscan Six Cheese",
      "garden_fresh": "Garden Fresh",
      "spinach_tomato_alfredo": "Fresh Spinach & Tomato Alfredo",
      "shaq_a_roni": "Shaq-a-Roni",
      "hawaiian_bbq_chicken": "Hawaiian BBQ Chicken",
      "zesty_italian_trio": "Zesty Italian Trio",
      "super_hawaiian": "Super Hawaiian",
      "works": "Works",
      "ultimate_pepperoni": "Ultimate Pepperoni",
      "fiery_buffalo_chicken": "Fiery Buffalo Chicken",
      "extra_cheesy_alfredo": "Extra Cheesy Alfredo",
      //"cheese": "Cheese",
      //"pepperoni": "Pepperoni",
      "double_cheeseburger": "Double Cheeseburger",

      //                        --- PapaJohn's UAE --->
      'all_the_meats': 'All the Meats',
      'chicken_super_papa': 'Chicken Super Papa',
      //'hawaiian': 'Hawaiian',
      //'pepperoni': 'Pepperoni',
      'super_papa': 'Super Papa',
      'chicken_bbq': 'Chicken BBQ',
      'garden_special': 'Garden Special',
      'margherita': 'Margherita',
      'spicy_chicken_ranch': 'Spicy Chicken Ranch',

      //                        --- PizzaHut --->
      'pepperoni_lovers': 'Pepperoni Lovers',
      'doble_pepperoni': 'Doble Pepperoni',
      //'pepperoni': 'Pepperoni', // (aka 'Pepperoni Nacional') also in PapaJohn's
      'suprema': 'Suprema',
      'super_suprema': 'Super Suprema',
      'meat_lovers': 'Meat Lovers',
      'veggie_lovers': 'Veggie Lovers',
      //'hawaiana': 'Hawaiana', // also in PapaJohn's
      'veggie': 'Veggie',
      //'napolitana': 'Napolitana', // also in PapaJohn's
      'meaty_bbq': 'Meaty BBQ',
      'espanola': 'Española',
      'espanolisima': 'Españolisima',
      'meaty': 'Meaty',
      //'italiana': 'Italiana', // also in PapaJohn's
      'american_meat': 'American Meat',
      'milano': 'Milano',
      'tejana_bbq': 'Tejana BBQ',
      '4_estaciones': '4 Estaciones',
      'bbq_meat_cheese_pops': 'BBQ Meat & Cheese Pops',

      //                        --- Dominos --->
      //'pepperoni': 'Pepperoni', //already defined
      'meatzzza': 'Meatzza',
      'meatzza': 'Meatzza',
      'deluxe': 'Deluxe',
      'extravaganzza': 'ExtravaganZZa',
      'cheeseburger': 'Cheeseburger',
      'philly_cheese_steak': 'Philly cheese steak',
      'chicken_taco': 'Chicken Taco',
      'buffalo_chicken': 'Buffalo chicken',
      'memphis_bbq_chicken': 'Memphis BBQ chicken',
      'cali_chicken_bacon_ranch': 'Cali chicken bacon ranch',
      'spinach_n_feta': 'Spinach & Feta',
      'pacific_veggie': 'Pacific veggie',
      'wisconsin_6_cheese': 'Wisconsin 6 cheese',
      'honolulu_hawaiian': 'Honolulu hawaiian',
      'extra_cheese': 'Extra Cheese',

      //                        --- Melt --->
      //"custom": "Custom",
      //'pepperoni': 'Pepperoni', //already defined
      'double_pepperoni': 'Double Pepperoni',
      'classic': 'Classic',
      'pesto_margarita': 'Pesto Margarita',

      //                        --- LittleCaesar_MX --->
      //"custom": "Custom",
      //"cheese": "Cheese",
      //'hawaiian': 'Hawaiian',
      //'pepperoni': 'Pepperoni',
      'meat': 'Meat',

      //                        --- CloverLeaf --->
      // same as LittleCaesar_MX

      //                        --- PizzaHut_TZ --->
      //"custom": "Custom",
      //'pepperoni': 'Pepperoni',
      //"cheese": "Cheese",
      'bbq_chicken_and_sausage': 'BBQ Chicken and Sausage',
      'sweet_chili_chicken': 'Sweet Chili Chicken',

      //                        --- PizzaHut_BR --->
      // same as Global
    }
  },
  "evaluation": {
    "Bordes": "Border size",
    "Limpieza": "Border cleanliness",
    "Sello de queso": "Cheese seal",
    "Cocción queso": "Cheese melting",
    "Queso interior": "Inner cheese",
    "Queso exterior": "Outer cheese",
    "Ingredientes interior": "Inner ingredients",
    "Ingredientes exterior": "Outer ingredients",
    "Size": "Size",
    "Circularity": "Circularity",
    "Ingredient Count": "Ingredient Count",
    "Ingredient Distrib": "Ingredient Distribution",
    "Cutting lines": "Cutting lines",
    "Baking": "Baking",
    "Cheese": 'Cheese',
    "Non Cheese": "Non Cheese",
    "Bubble": "Bubble",
  },
  "evaluation_soft": {
    "Pizza size": "Pizza size",
    "Border size": "Border size",
    "Cheese on border": "Cheese on border",
    "Cheese seal": "Cheese seal",
    "Cheese cover": "Cheese cover",
    "Ingredients": "Ingredients"
  }
}

const SORT_BY = {
  ...(((process.env.REACT_APP_DEMO || 'false').toLowerCase() === 'false') && {
    'Ascending score': {
      sortFunction: (cards) => {
        return cards.sort((a, b) => (parseFloat(a.score) > parseFloat(b.score)) ? 1 : ((parseFloat(b.score) > parseFloat(a.score)) || a.score === '' ? -1 : 0));
      },
      default: false
    },
    'Descending score': {
      sortFunction: (cards) => {
        return cards.sort((a, b) => (parseFloat(a.score) > parseFloat(b.score)) || b.score === '' ? -1 : ((parseFloat(b.score) > parseFloat(a.score)) ? 1 : 0));
      },
      default: false
    },
    'Specialty A - Z': {
      sortFunction: (cards) => {
        return cards.sort((a, b) => (a.specialty > b.specialty) ? 1 : ((b.specialty > a.specialty) ? -1 : 0));
      },
      default: false
    },
    'Specialty Z - A': {
      sortFunction: (cards) => {
        return cards.sort((a, b) => (a.specialty > b.specialty) ? -1 : ((b.specialty > a.specialty) ? 1 : 0));
      },
      default: false
    },
  }),
  'Latest First': {
    sortFunction: (cards) => {
      return cards.sort((a, b) => (a.ts > b.ts) ? -1 : ((b.ts > a.ts) ? 1 : 0));
    },
    default: false
  },
  'Oldest First': {
    sortFunction: (cards) => {
      return cards.sort((a, b) => (a.ts > b.ts) ? 1 : ((b.ts > a.ts) ? -1 : 0));
    },
    default: true
  },
}

const SCORE_THRESHOLD = {
  good: 8,
  regular: 6
}


let defaultFilterConfig = {
  multiple: true,
  checkboxes: true,
  type: 'select',
  name: 'base',
  visible: true,
  dailyFeedOnly: true,
}

const MAIN_FILTERS = {
  'Select store': {
    ...defaultFilterConfig,
    label: 'Select store',
    name: 'Select store',
    optionsList: [],
    multiple: false
  },
  'Select date': {
    ...defaultFilterConfig,
    label: 'Select date',
    name: 'Select date',
    type: 'datePicker'
  }
}

const CARDS_FILTERS = {
  'Has comment': {
    ...defaultFilterConfig,
    visible: false,
    name: 'Has comment',
    optionsList: [
      { name: 'Yes', value: '1' },
      { name: 'No', value: '0' }
    ],
    eval: (data, selection) => {
      if (selection.length !== 1) return true;
      return !([undefined, ''].includes(data.gtExtra)) === selection[0].value
    },
  },
  ...(((process.env.REACT_APP_DEMO || 'false').toLowerCase() === 'false') && {
    'Scored': {
      ...defaultFilterConfig,
      dailyFeedOnly: false,
      name: 'Scored',
      optionsList: [
        { name: 'Yes', value: 1 },
        { name: 'No', value: 0 }
      ],
      multiple: false,
      eval: (data, selection) => {
        if (selection.length !== 1) return true;
        return !([undefined, ''].includes(data.score)) === Boolean(selection[0].value)
      },
    },
  }),
  'Period': {
    ...defaultFilterConfig,
    name: 'Period',
    min: 0,
    max: 24,
    type: 'range',
    eval: (data, selection) => {
      if ((selection.min && selection.max) === undefined) return true;
      if (!data.ts) return false;
      let minTs = new Date(data.ts.getTime());
      minTs.setHours(Math.floor(selection.min));
      minTs.setMinutes(selection.min % 1 * 60);
      let maxTs = new Date(data.ts.getTime());
      maxTs.setHours(Math.floor(selection.max));
      maxTs.setMinutes(selection.max % 1 * 60);
      return data.ts >= minTs && data.ts < maxTs;
    }
  },
  ...(((process.env.REACT_APP_DEMO || 'false').toLowerCase() === 'false') && {
    'All specialties': {
      ...defaultFilterConfig,
      name: 'All specialties',
      optionsList: [],
      eval: (data, selection) => {
        if (selection.length === 0) return true;
        let specialitieNames = selection.map((option) => {
          return option.value;
        })
        if (data.specialty === 'Unknown (CYO)' && specialitieNames.includes('Custom')) return true;
        return specialitieNames.includes(data.specialty) ? true : false;
      }
    },
    'Crust type': {
      ...defaultFilterConfig,
      dailyFeedOnly: false,
      name: 'Crust type',
      optionsList: [
        { name: 'Original', value: 'tradicional' },
        { name: 'Thin', value: 'delgada' }
      ],
      multiple: false,
      eval: (data, selection) => {
        if (selection.length !== 1) return true;
        return (data.crustType || undefined) === selection[0].value
      }
    }
  }),
  'Reviewed': {
    ...defaultFilterConfig,
    name: 'Reviewed',
    feedbackFilter: true, 
    optionsList: [
      { name: 'Yes', value: 1 },
      { name: 'No', value: 0 }
    ],
    multiple: false,
    eval: (data, selection) => {
      if (selection.length !== 1) return true;
      return (data.reviewer !== undefined) === Boolean(selection[0].value)
    },
  },
  'Hidden': {
    ...defaultFilterConfig,
    name: 'Hidden',
    feedbackFilter: true, 
    optionsList: [
      { name: 'Yes', value: 1 },
      { name: 'No', value: 0 }
    ],
    multiple: false,
    eval: (data, selection) => {
      if (selection.length !== 1) return true;
      return (data.censored ?? 0) === selection[0].value;
    },
  },
  'Standard': {
    ...defaultFilterConfig,
    name: 'Standard',
    feedbackFilter: true, 
    optionsList: [
      { name: 'To Standard', value: 'to_standard' },
      { name: 'Not to Standard', value: 'not_to_standard' },
      { name: 'Maybe to Standard', value: 'maybe_to_standard' }
    ],
    multiple: false,
    eval: (data, selection) => {
      if (selection.length !== 1) return true;
      return data.standardAttr === selection[0].value;
    },
  }
}

const BASE_POINTS = 1.2;
const NON_PEPPERONI_ING_ADJUST = 2.5;
const PEPPERONI_SPECS = [
  'super_pepperoni',
  'ultimate_pepperoni',
  'pepperoni',
  'shaq_a_roni'
];

const INGREDIENT_ICON = {
  pepperoni: pepperoni,
  ham: ham,
  bacon: bacon,
  chorizo: sausage,
  meat: beef,
  chicken: chicken,
  steak: beef
}

const USERS_PAGE_SIZE = 6;

const getSpecialtiesByLanguage = (language) => {
  return UI_TEXT.specialties[language] ?? UI_TEXT.specialties['en']
}

const TS_START_OF_WEEK_CHANGE = '2024-03-05';

export {
  SORT_BY,
  SCORE_THRESHOLD,
  CARDS_FILTERS,
  MAIN_FILTERS,
  UI_TEXT,
  BASE_POINTS,
  NON_PEPPERONI_ING_ADJUST,
  PEPPERONI_SPECS,
  INGREDIENT_ICON,
  USERS_PAGE_SIZE,
  TS_START_OF_WEEK_CHANGE,
  getSpecialtiesByLanguage
}