import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import style from './MonthlyReportPreview.module.css'
import logoBlack from '../../../../vectors/logo_black.svg';
import check from '../../../../vectors/check.svg';
import mark from '../../../../vectors/mark.svg';
import { FetchService } from '../../../../services/Api';
import totalProductionIcon from '../../../../img/total_production_icon.png';
import Plot from 'react-plotly.js';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from '../../../common/loadingScreen/LoadingScreen';
import firstPlace from '../../../../vectors/first-place.svg';
import secondPlace from '../../../../vectors/second-place.svg';
import thirdPlace from '../../../../vectors/third-place.svg';
import top1 from '../../../../img/top_1_kwali_icon.png';
import { MONTHS, PROBLEMS, SPECIALTIES_ICONS, paginator, colorVariation, DAYS } from '../../Utils';
import { getPagePictures, sendScreenshots, generatePDFWithSelenium } from '../../PDFGeneration';
import papa_johns_logo from '../../../../img/papa_johns_logo.png';
import { getCorrectUiText } from '../../UIText';


const COLORS = [
  '#4472c4',
  '#ed7d31',
  '#a5a5a5',
  '#ffc000',
  '#5b9bd5',
  '#71ad47',
  '#284679',
  '#f54df7',
  '#77f2ff',
  '#fdc00e',
  '#11111B',
  '#ff0000'
]

const SYMBOLS = [
  'square',
  'hexagon',
  'diamond',
  'triangle-left',
  'triangle-right',
  'x',
  'cross',
  'circle',
  'y-up',
  'y-down',
  'star',
  'diamond-tall',
  'star-triangle-up'
]

const LEGACY_DEFAULT_SPECIALTIES = [
  'pepperoni',
  'cheese'
]

const STORES_EVOLUTION_PAGE_MAX_STORES = 12;

const MonthlyReportPreview = (props) => {
  const navigate = useNavigate();
  const { company, month, region } = useParams();
  const [loading, setLoading] = useState(false);
  const [monthName, setMonthName] = useState('');
  const [oldMonthsGraphsDataReady, setOldMonthsGraphsDataReady] = useState(false);
  const [mainProblem, setMainProblem] = useState(undefined);
  const [reportByMonth, setReportByMonth] = useState([]);
  const [reportByMonthCurrentCompany, setReportByMonthCurrentCompany] = useState([]);
  const [presentStores, setPresentStores] = useState({});
  const [problems, setProblems] = useState([]);
  const [cards, setCards] = useState([]);
  const [ranking, setRanking] = useState([]);
  const [data, setData] = useState(undefined);
  const [currentCompanyReport, setCurrentCompanyReport] = useState(undefined);
  const [bestStore, setBestStore] = useState(undefined);
  const [bestStoreCompany, setBestStoreCompany] = useState(undefined);
  const [shiftPerformance, setShiftPerformance] = useState([]);
  const [rankingPages, setRankingPages] = useState([]);
  const [reportSpecialties, setReportSpecialties] = useState(undefined);
  const [blobs, setBlobs] = useState(undefined);
  const [bestStoreDelta, setBestStoreDelta] = useState(undefined);
  const [pjAverage, setPjAverage] = useState(undefined);
  const [reportStructure, setReportStructure] = useState(undefined);
  const [language, setLanguage] = useState('en');
  const [pizzasImages, setPizzasImages] = useState([]);
  const [imagesReady, setImagesReady] = useState(false);
  const [readyForSelenium, setReadyForSelenium] = useState(false);
  const [foodCostOpportunities, setFoodCostOpportunities] = useState(false);
  const [inSeleniumQueue, setInSeleniumQueue] = useState(false);

  useEffect(() => {
    if (reportStructure !== undefined) return;
    let requestConf = {
      endpoint: `pizza_tv/report_structure/`,
      method: 'GET'
    }
    setLoading(true);
    FetchService(requestConf)
      .then(response => setReportStructure(response.data))
      .catch(err => console.log(err));
  }, [reportStructure])

  useEffect(() => {
    if (data !== undefined) return;
    let requestConf = {
      endpoint: `pizza_tv/report/monthly/company_pk/${company}/month_stamp/${month}/`,
      method: 'GET',
      data: {
        complete_data: 1
      }
    }
    setLoading(true);
    FetchService(requestConf)
      .then(response => {
        setData(response.data.data.reports[0]);
        setLanguage(response.data.data.report_language);
      })
      .catch(err => console.log(err));
  }, [company, month, data, region])

  useEffect(() => {
    if (data === undefined) return;
    let requestConf = {
      endpoint: `pizza_tv/conglomerate/company_pk/${company}/month_stamp/${month}/`,
      method: 'GET',
    }
    FetchService(requestConf)
      .then(response => {
        setPjAverage(response.data);
      })
      .catch(err => console.log(err))
  }, [data, month, company])

  useEffect(() => {
    if (data === undefined || Object.keys(data).length === 0) return;
    for (let report of data[Object.keys(data).sort().reverse()[0]]) {
      if (report.franchise === company) {
        setReportSpecialties(report.specialties || LEGACY_DEFAULT_SPECIALTIES);
        return;
      }
    }
  }, [data, company])

  const checkFoodCostOpportunities = (stores, report) => {
    for (let store of stores) {
      if (report.resume_by_store[store].over_pepperoni_by_shift) {
        for (let weekday of DAYS) {
          for (let shift of ['AM', 'PM']) {
            let keys = Object.keys(report.resume_by_store[store].over_pepperoni_by_shift[weekday][shift])
            for (let key of keys) {
              if (report.resume_by_store[store].over_pepperoni_by_shift[weekday][shift][key] !== 0) return true;
            }
          }
        }
      }
    }
    return false;
  }

  useEffect(() => {
    if (data === undefined || reportSpecialties === undefined || pjAverage === undefined) return;
    let toStandardPercentage = undefined;
    let bestStoreName = undefined;
    let bestStore = undefined;
    let bestStoreCompanyPrettyName = undefined;
    let bestStoreCompany = undefined;
    let bestStoreDelta = 0;
    let reportByMonth = [];
    let reportByMonthCurrentCompany = [];
    let currentCompanyReport = undefined;
    let monthName = undefined;
    let ranking = [];
    let toStandardFt = [];
    let noToStandardFt = [];
    let problems = [];
    let presentStores = {};
    let foodCostOpportunities = false;

    // Find last month best store
    let lastMonthKey = `${month.split('-')[2]}-${month.split('-')[1].padStart(2, '0')}-${month.split('-')[0].padStart(2, '0')}`;
    let toFindBestStore = data[lastMonthKey] ? data[lastMonthKey].filter(report => !report.region) : [];
    for (let report of toFindBestStore) {
      for (let store of Object.keys(report.resume_by_store)) {
        if (toStandardPercentage === undefined || report.resume_by_store[store].to_standard_percentage > toStandardPercentage) {
          toStandardPercentage = report.resume_by_store[store].to_standard_percentage;
          bestStoreName = store;
          bestStore = report.resume_by_store[store];
          bestStore.pk = store;
          bestStoreCompany = report.franchise;
          bestStoreCompanyPrettyName = report.doc.franchise ?? report.franchise;
        }
      }
    }
    // Gather stores info by month stamp, current company stores and conglomerate best store
    for (let [index, monthStamp] of Object.keys(data).sort().reverse().entries()) {
      let month_ = Number(monthStamp.split('-')[1]),
        year_ = Number(monthStamp.split('-')[0]);
      if (index === 0 && (month_ !== Number(month.split('-')[1]) || year_ !== Number(month.split('-')[2]))) return;
      let dict = {
        month: MONTHS[Number(month_) - 1],
        monthKey: MONTHS[Number(month_) - 1],
        stores: {},
        report: {
          resume_by_store: [],
          problems: {},
          to_standard_percentage: undefined
        }
      }
      for (const specialty of reportSpecialties) {
        dict.report[`to_standard_percentage_${specialty}`] = undefined;
      }
      let includeMonth = false;
      let validReports = data[monthStamp].filter(report => region ? (report.region === region || (!report.region && report.franchise === bestStoreCompany)) : !report.region);
      for (let report of validReports) {
        if (report.franchise === company && ((region && report.region === region) || !region)) {
          includeMonth = true;
          if (index === 0) {
            currentCompanyReport = report;
            monthName = dict.monthKey;
          }
          if (index === 0) {
            foodCostOpportunities = checkFoodCostOpportunities(Object.keys(report.resume_by_store), report);
          }
          for (let store of Object.keys(report.resume_by_store)) {
            if (index === 0) {
              ranking.push(report.resume_by_store[store]);
            }
            presentStores[store] = { name: report.resume_by_store[store].name };
            dict.stores[store] = report.resume_by_store[store];
            dict.stores[store].company = report.franchise;
            dict.report = report;
          }
        }
        else if (report.franchise === bestStoreCompany) {
          for (let store of Object.keys(report.resume_by_store)) {
            if (store === bestStoreName) {
              presentStores[store] = { name: report.resume_by_store[store].name };
              if (!(store in dict.stores)) {
                dict.stores[store] = report.resume_by_store[store];
                dict.stores[store].company = report.franchise;
              }
            }
          }
        }
      }
      if (includeMonth) reportByMonthCurrentCompany.unshift(dict);
      if ((bestStoreCompany === company && includeMonth) || bestStoreCompany !== company) reportByMonth.unshift(dict);
    }
    // Calculate deltas
    for (let store of Object.keys(reportByMonth[reportByMonth.length - 1].stores)) {
      let delta = 0;
      let currentMonthToStandardPercentage = reportByMonth[reportByMonth.length - 1].stores[store].to_standard_percentage;
      let index = reportByMonth.length - 2
      while (index >= 0) {
        if (reportByMonth[index].stores[store]) {
          let lastMonthToStandardPercentage = reportByMonth[index].stores[store].to_standard_percentage;
          delta = (currentMonthToStandardPercentage - lastMonthToStandardPercentage) * 100;
          if (store === bestStoreName) {
            bestStoreDelta = delta;
          }
          break;
        }
        index -= 1
      }
      reportByMonth[reportByMonth.length - 1].stores[store]['delta'] = delta;
    }
    // Fill empty months
    let nextExpectedIndex = MONTHS.indexOf(reportByMonth[0].monthKey);
    for (let i = 0; i < reportByMonth.length; i++) {
      if (nextExpectedIndex !== MONTHS.indexOf(reportByMonth[i].monthKey)) {
        let dict = {
          month: MONTHS[nextExpectedIndex].substring(0, 3),
          stores: [],
          report: {
            resume_by_store: [],
            problems: {},
            to_standard_percentage: undefined
          },
          monthKey: MONTHS[nextExpectedIndex]
        }
        for (const specialty of reportSpecialties) {
          dict.report[`to_standard_percentage_${specialty}`] = undefined;
        }
        reportByMonth.splice(i, 0, dict);
      }
      nextExpectedIndex = (nextExpectedIndex + 1) % 12;
    }
    nextExpectedIndex = MONTHS.indexOf(reportByMonthCurrentCompany[0].monthKey);
    for (let i = 0; i < reportByMonthCurrentCompany.length; i++) {
      if (nextExpectedIndex !== MONTHS.indexOf(reportByMonthCurrentCompany[i].monthKey)) {
        let dict = {
          month: MONTHS[nextExpectedIndex].substring(0, 3),
          stores: [],
          report: {
            resume_by_store: [],
            problems: {},
            to_standard_percentage: undefined
          },
          monthKey: MONTHS[nextExpectedIndex]
        }
        for (const specialty of reportSpecialties) {
          dict.report[`to_standard_percentage_${specialty}`] = undefined;
        }
        reportByMonthCurrentCompany.splice(i, 0, dict);
      }
      nextExpectedIndex = (nextExpectedIndex + 1) % 12;
    }
    // Sort ranking
    ranking.sort((a, b) => a.to_standard_percentage > b.to_standard_percentage ? -1 : a.to_standard_percentage < b.to_standard_percentage ? 1 : 0);
    ranking = ranking.map((r, index) => ({ ...r, index: index }))
    if (pjAverage.to_standard_percentage) {
      let i = 0;
      for (; i < ranking.length; i++) {
        if (pjAverage.to_standard_percentage > ranking[i].to_standard_percentage) break;
      }
      ranking.splice(i, 0, { to_standard_percentage: pjAverage.to_standard_percentage, pjAverage: true });
    }
    // Get featured pizzas
    toStandardFt = currentCompanyReport.features.filter(pizza => pizza.standard === 'to_standard').sort((a, b) => a.score > b.score ? -1 : a.score < b.score ? 1 : 0).slice(0, 15);
    noToStandardFt = currentCompanyReport.features.filter(pizza => pizza.standard === 'not_to_standard').sort((a, b) => a.score < b.score ? -1 : a.score > b.score ? 1 : 0).slice(0, 15);
    // Ranking problems
    for (let item of Object.keys(currentCompanyReport.problems)) {
      if (item !== 'count') problems.push({
        id: item,
        count: currentCompanyReport.problems[item],
        name: PROBLEMS[item].name,
        percentage: currentCompanyReport.problems[item] / currentCompanyReport.problems.count,
        icon: PROBLEMS[item].icon
      });
    }
    setFoodCostOpportunities(foodCostOpportunities);
    setPresentStores(presentStores);
    setRanking(ranking);
    setProblems(problems.sort((a, b) => a.percentage > b.percentage ? -1 : a.percentage < b.percentage ? 1 : 0));
    setMainProblem(problems[0]);
    setReportByMonth(reportByMonth.slice(-12));
    setReportByMonthCurrentCompany(reportByMonthCurrentCompany.slice(-12));
    setCurrentCompanyReport(currentCompanyReport);
    setMonthName(monthName);
    setBestStore(bestStore);
    setBestStoreCompany(bestStoreCompanyPrettyName);
    setBestStoreDelta(bestStoreDelta);
    setCards(toStandardFt.concat(noToStandardFt));
    setPizzasImages(Array(toStandardFt.concat(noToStandardFt).length).fill(null).map(_ => ({ loaded: false })));
    setLoading(false);
  }, [data, company, reportSpecialties, month, region, pjAverage])

  useEffect(() => {
    let requestConf = {
      endpoint: `pizza_tv/selenium/state/`,
      method: 'GET'
    }
    FetchService(requestConf)
      .then(response => {
        if ((response.data.reports || []).map(r => r._id).includes(currentCompanyReport._id)) setInSeleniumQueue(true);
      })
      .catch(err => console.log(err));
  }, [currentCompanyReport])

  useEffect(() => {
    if (ranking.length === 0) return;
    setShiftPerformance(paginator(8, 9, 4, ranking));
    setRankingPages(paginator(17, 20, 5, ranking));
    setOldMonthsGraphsDataReady(true);
  }, [ranking])

  const problemsEvolutionTrace = (reportByMonth) => {
    let traces = [];
    for (let [index, problem] of Object.keys(PROBLEMS).sort((a, b) => a > b ? 1 : a < b ? -1 : 0).entries()) {
      let values = reportByMonth.map(item => getProblemAvg(problem, item));
      let months = reportByMonth.map(item => getCorrectUiText(item.month, language).slice(0, 3));
      for (let i = 0, j = i + 1; j < values.length; i++, j++) {
        if (values[i] === undefined) continue;
        if (values[j] === undefined) {
          i--;
          continue;
        }
        traces.push({
          x: [months[i], months[j]],
          y: [values[i], values[j]],
          mode: 'lines',
          type: 'scatter',
          line: {
            color: COLORS[(COLORS.length - 1) - index],
            width: 4
          }
        })
        i = j - 1
        j = i + 1;
      }
    }
    return traces;
  }

  const storesEvolutionTrace = (reportByMonth, presentStores) => {
    let traces = [];
    for (let [index, store] of getStoresOrder(presentStores).entries()) {
      let values = reportByMonth.map(item => { return item.stores[store] === undefined || item.stores[store].count === 0 ? undefined : item.stores[store].to_standard_percentage });
      let months = reportByMonth.map(item => getCorrectUiText(item.month, language).slice(0, 3));
      for (let i = 0, j = i + 1; j < values.length; i++, j++) {
        if (values[i] === undefined) continue;
        if (values[j] === undefined) {
          i--;
          continue;
        }
        traces.push({
          x: [months[i], months[j]],
          y: [values[i], values[j]],
          mode: 'lines',
          type: 'scatter',
          line: {
            color: COLORS[index % COLORS.length],
            width: 2
          }
        })
        i = j - 1
        j = i + 1;
      }
    }
    let values = getCompanyAvg();
    let months = reportByMonth.map(item => getCorrectUiText(item.month, language).slice(0, 3));
    for (let i = 0, j = i + 1; j < values.length; i++, j++) {
      if (values[i] === undefined) continue;
      if (values[j] === undefined) {
        i--;
        continue;
      }
      traces.push({
        x: [months[i], months[j]],
        y: [values[i], values[j]],
        mode: 'lines',
        type: 'scatter',
        line: {
          dash: 'dash',
          color: 'black',
          width: 3
        }
      })
      i = j - 1
      j = i + 1;
    }
    return traces;
  }

  useEffect(() => {
    if (blobs === undefined) return;
    sendScreenshots(currentCompanyReport, blobs, undefined, undefined, setLoading, true, navigate);
  }, [blobs, currentCompanyReport, navigate])

  const getCompanyAvg = useCallback(() => {
    return reportByMonth.map(item => {
      let values = Object.keys(item.stores).filter(store => item.stores[store].company === company).map(store => item.stores[store].to_standard_percentage);
      if (values.length === 0) return undefined;
      let sum = values.reduce((acc, num) => acc + num, 0);
      return sum / values.length;
    })
  }, [reportByMonth, company])

  const getProblemAvg = (problem, item) => {
    let problemsCount = Object.keys(item.report.resume_by_store).map(store => item.report.resume_by_store[store].problems ? item.report.resume_by_store[store].problems[problem] : 0);
    problemsCount = problemsCount.filter(problem => problem);
    if (problemsCount.length === 0) return undefined;
    return ((problemsCount.reduce((acc, val) => acc + (val ?? 0), 0) / item.report.reports_count) * (365 / 12) / 7).toFixed(1) * 1;
  }

  const getStoresOrder = useCallback((stores) => {
    let a = Object.keys(stores).filter(store => store === bestStore.pk).concat(Object.keys(stores).filter(store => store !== bestStore.pk).sort((a, b) => {
      if (stores[a].name < stores[b].name) return -1;
      if (stores[a].name > stores[b].name) return 1;
      return 0;
    }
    ))
    return a
  }, [bestStore])

  const handleImageLoad = useCallback((index) => {
    setPizzasImages(prevData => {
      return prevData.map((obj, idx) => {
        if (idx === index) return { loaded: true };
        return { ...obj };
      })
    })
  }, [])

  useEffect(() => {
    if (imagesReady) {
      setReadyForSelenium(true);
    }
  }, [imagesReady])

  useEffect(() => {
    if (pizzasImages.length === 0) return;
    for (let pizza of pizzasImages) {
      if (!pizza.loaded) return;
    }
    setImagesReady(true);
  }, [pizzasImages])

  return (
    <LoadingScreen loading={loading}>
      {
        readyForSelenium ? (
          <div id='readyForSelenium' style={{ display: 'none' }}></div>
        ) : (
          null
        )
      }
      <div className={`${style.flowButtons} invisibleForSelenium`}>
        {
          inSeleniumQueue === false ? (
            <React.Fragment>
              <button style={{ backgroundColor: '#70ad47', color: 'white', border: '1px solid gray' }} className={style.flowButton} onClick={() => getPagePictures(blobs, setLoading, setBlobs, false)}>{'Generate File'}</button>
              <button style={{ backgroundColor: '#70ad47', color: 'white', border: '1px solid gray' }} className={style.flowButton} onClick={() => generatePDFWithSelenium([currentCompanyReport], setLoading, navigate, month, true)}>{'Generate File (Selenium)'}</button>
            </React.Fragment>
          ) : (
            null
          )
        }
        <button style={{ backgroundColor: '#70ad47', color: 'white', border: '1px solid gray' }} className={style.flowButton} onClick={() => navigate('/pizza-tv-month-explorer')}>{'Finish'}</button>
      </div>
      <div className={style.wrapper}>
        <div className={style.pageContent}>
          {
            oldMonthsGraphsDataReady ? (
              <div id='completeReport'>
                {/*
                // Cover with background image
                <div className={`${style.reportPage} pdfPage`} style={{ padding: 'inherit', backgroundImage: `url(${cover})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPositionX: '37%' }}>
                */}
                <div className={`${style.reportPage} pdfPage`} style={{ padding: 'inherit' }}>
                  <img className={style.coverBrand} src={logoBlack} alt='kwali' />
                  <div className={style.coverInfo}>
                    <span className={style.coverInfoTitle}>{getCorrectUiText('Monthly Quality Tracking', language)}</span>
                    <div className={style.coverInfoDescription}>
                      <span>{`${currentCompanyReport.doc.franchise}${region ? `-${currentCompanyReport.doc.region}` : ''}`}</span>
                      <span>{`${getCorrectUiText(monthName, language)} - ${getCorrectUiText('Report', language)} #${currentCompanyReport.doc.number}`}</span>
                      <span>{getCorrectUiText('Number of stores', language)}: {Object.keys(currentCompanyReport.resume_by_store).length}</span>
                    </div>
                  </div>
                </div>
                <div className={`${style.reportPage} pdfPage`} style={{ paddingRight: '4%', paddingLeft: '4%' }} >
                  <span className={style.pageTitle}>{getCorrectUiText('Company performance', language)}</span>
                  <span className={style.disclaimer}>{getCorrectUiText('(% Pizzas to standard accumulated in the last month)', language)}</span>
                  <table className={style.resumeTable}>
                    <tbody>
                      <tr>
                        <th>{getCorrectUiText('Month', language)}</th>
                        <th>{getCorrectUiText('# Stores in report', language)}</th>
                        <th>{getCorrectUiText('Reviewed Pizzas', language)}</th>
                        <th>{getCorrectUiText('Pizzas to standard', language)}</th>
                      </tr>
                      <tr>
                        <td>{getCorrectUiText(monthName, language)}</td>
                        <td>{Object.keys(currentCompanyReport.resume_by_store).length}</td>
                        <td>{currentCompanyReport.count}</td>
                        <td>{(currentCompanyReport.to_standard_percentage * 100).toFixed(0)}%</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className={style.chartsWrapper}>
                    <div className={style.charts} style={{ gap: `${50 * (1 / ((reportSpecialties.length + 1) / 3))}px` }}>
                      {
                        reportSpecialties.map((specialty, index) => {
                          return <div className={style.specialtyChart} key={index}>
                            <div className={style.chartLegend}>
                              <img src={SPECIALTIES_ICONS[specialty]} alt='Pizza' />
                              <span>{getCorrectUiText(reportStructure.REPORT_SPECIALTIES_DEFINITION[specialty] ? (reportStructure.REPORT_SPECIALTIES_DEFINITION[specialty].ui_text ?? specialty) : specialty, language)}<br />{getCorrectUiText('To Standard', language)}</span>
                            </div>
                            <div className={style.plot}>
                              <Plot
                                data={[
                                  {
                                    type: 'bar',
                                    x: reportByMonthCurrentCompany.slice(0, -1).map(item => getCorrectUiText(item.month, language).slice(0, 3)),
                                    y: reportByMonthCurrentCompany.slice(0, -1).map(item => item.report[`to_standard_percentage_${specialty}`]),
                                    textposition: 'outside',
                                    text: reportByMonthCurrentCompany.slice(0, -1).map(item => item.report[`to_standard_percentage_${specialty}`] === undefined ? '' : `<span style="font-weight: bold">${(item.report[`to_standard_percentage_${specialty}`] * 100).toFixed(0)}%</span>`),
                                    name: `${reportStructure.REPORT_SPECIALTIES_DEFINITION[specialty] ? (reportStructure.REPORT_SPECIALTIES_DEFINITION[specialty].ui_text ?? specialty) : specialty}`,
                                    marker: {
                                      color: 'white',
                                      line: {
                                        color: 'black',
                                        width: 2
                                      }
                                    },
                                    textfont: {
                                      size: 25,
                                    }
                                  },
                                  {
                                    type: 'bar',
                                    x: reportByMonthCurrentCompany.slice(-1).map(item => getCorrectUiText(item.month, language).slice(0, 3)),
                                    y: reportByMonthCurrentCompany.slice(-1).map(item => item.report[`to_standard_percentage_${specialty}`]),
                                    textposition: 'outside',
                                    text: reportByMonthCurrentCompany.slice(-1).map(item => item.report[`to_standard_percentage_${specialty}`] === undefined ? '' : `<span style="font-weight: bold">${(item.report[`to_standard_percentage_${specialty}`] * 100).toFixed(0)}%</span>`),
                                    name: `${reportStructure.REPORT_SPECIALTIES_DEFINITION[specialty] ? (reportStructure.REPORT_SPECIALTIES_DEFINITION[specialty].ui_text ?? specialty) : specialty}`,
                                    marker: {
                                      color: '#4472c4',
                                      line: {
                                        color: 'black',
                                        width: 2
                                      }
                                    },
                                    textfont: {
                                      size: 25,
                                    }
                                  },
                                ]}
                                layout={{
                                  height: 470 * (1 / ((reportSpecialties.length + 1) / 3)),
                                  autosize: true,
                                  margin: {
                                    t: 80 * (1 / ((reportSpecialties.length + 1) / 3)),
                                    l: 80,
                                    r: 40,
                                    b: 80 * (1 / ((reportSpecialties.length + 1) / 3))
                                  },
                                  yaxis: {
                                    automargin: true,
                                    tickformat: ',.0%',
                                    showline: false,
                                    showticklabels: true,
                                    showgrid: false,
                                    zeroline: true,
                                    range: [0, 1.05],
                                    rangemode: 'tozero',
                                    tickfont: {
                                      size: 25
                                    }
                                  },
                                  xaxis: {
                                    showgrid: false,
                                    tickfont: {
                                      size: 25
                                    }
                                  },
                                  paper_bgcolor: 'rgba(255,255,255,1)',
                                  plot_bgcolor: 'rgba(255,255,255,1)',
                                  showlegend: false,

                                }}
                                config={{ displayModeBar: false }}
                                useResizeHandler={true}
                                style={{ width: "100%", height: "100%" }}
                              />
                            </div>
                          </div>
                        })
                      }
                      <div className={style.specialtyChart}>
                        <div className={style.chartLegend}>
                          <img src={totalProductionIcon} alt='Pizza' />
                          <span>{getCorrectUiText('Total Production', language)}<br />{getCorrectUiText('To Standard', language)}</span>
                        </div>
                        <div className={style.plot}>
                          <Plot
                            data={[
                              {
                                type: 'bar',
                                x: reportByMonthCurrentCompany.slice(0, -1).map(item => getCorrectUiText(item.month, language).slice(0, 3)),
                                y: reportByMonthCurrentCompany.slice(0, -1).map(item => item.report.to_standard_percentage),
                                textposition: 'outside',
                                text: reportByMonthCurrentCompany.slice(0, -1).map(item => item.report.to_standard_percentage === undefined ? '' : `<span style="font-weight: bold">${(item.report.to_standard_percentage * 100).toFixed(0)}%</span>`),
                                name: 'Total Production',
                                marker: {
                                  color: 'white',
                                  line: {
                                    color: 'black',
                                    width: 2
                                  }
                                },
                                textfont: {
                                  size: 25,
                                }
                              },
                              {
                                type: 'bar',
                                x: reportByMonthCurrentCompany.slice(-1).map(item => getCorrectUiText(item.month, language).slice(0, 3)),
                                y: reportByMonthCurrentCompany.slice(-1).map(item => item.report.to_standard_percentage),
                                textposition: 'outside',
                                text: reportByMonthCurrentCompany.slice(-1).map(item => item.report.to_standard_percentage === undefined ? '' : `<span style="font-weight: bold">${(item.report.to_standard_percentage * 100).toFixed(0)}%</span>`),
                                name: 'Total Production',
                                marker: {
                                  color: '#4472c4',
                                  line: {
                                    color: 'black',
                                    width: 2
                                  }
                                },
                                textfont: {
                                  size: 25,
                                }
                              },
                            ]}
                            layout={{
                              height: 470 * (1 / ((reportSpecialties.length + 1) / 3)),
                              autosize: true,
                              margin: {
                                t: 80 * (1 / ((reportSpecialties.length + 1) / 3)),
                                l: 80,
                                r: 40,
                                b: 80 * (1 / ((reportSpecialties.length + 1) / 3))
                              },
                              yaxis: {
                                automargin: true,
                                tickformat: ',.0%',
                                showline: false,
                                showticklabels: true,
                                showgrid: false,
                                zeroline: true,
                                range: [0, 1.05],
                                rangemode: 'tozero',
                                tickfont: {
                                  size: 25
                                }
                              },
                              xaxis: {
                                showgrid: false,
                                tickfont: {
                                  size: 25
                                }
                              },
                              paper_bgcolor: 'rgba(255,255,255,1)',
                              plot_bgcolor: 'rgba(255,255,255,1)',
                              showlegend: false,

                            }}
                            config={{ displayModeBar: false }}
                            useResizeHandler={true}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <img className={style.kwaliSeal} src={logoBlack} alt='kwali' />
                </div>
                {
                  rankingPages.map((ranking, index) => {
                    return <div className={`${style.reportPage} pdfPage`} style={{ paddingRight: '4%', paddingLeft: '4%' }} key={index}>
                      {
                        index === 0 ? (
                          <React.Fragment>
                            <span className={style.pageTitle}>{getCorrectUiText('Stores ranking', language)}</span>
                            <span className={style.disclaimer}>{getCorrectUiText('(% Pizzas to standard accumulated in the last month)', language)}</span>
                          </React.Fragment>
                        ) : (
                          null
                        )
                      }
                      <div className={style.ranking}>
                        <table className={style.rankingTable}>
                          <tbody>
                            <tr>
                              <th style={{ width: '30%' }}>{getCorrectUiText('Store', language)}</th>
                              <th>{getCorrectUiText('% Pizzas to standard', language)}</th>
                              <th style={{ width: '15%' }}>{getCorrectUiText('∆ vs Last report', language)}</th>
                            </tr>
                          </tbody>
                        </table>
                        {
                          index === 0 && bestStore !== undefined ? (
                            <table className={style.rankingTable} style={{ marginTop: '50px' }}>
                              <tbody>
                                <tr className={`${style.rankingRow} ${style.podiumRow}`}>
                                  <td style={{ width: '10%' }}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                      <img src={top1} alt='' style={{ height: '75px' }} />
                                    </div>
                                  </td>
                                  <td style={{ width: '20%', textAlign: 'right', paddingRight: '20px', lineHeight: '1' }}>
                                    <span>
                                      <React.Fragment>
                                        {
                                          pjAverage.to_standard_percentage ? (
                                            <React.Fragment>
                                              {bestStoreCompany}
                                              <br />
                                            </React.Fragment>
                                          ) : (null)
                                        }
                                        {bestStore.name}
                                        <br />
                                        <small>{getCorrectUiText(`Best ${pjAverage.to_standard_percentage ? 'PJ ' : ''}Store`, language)}</small>
                                      </React.Fragment>
                                    </span>
                                  </td>
                                  <td style={{ borderLeft: '3px solid #bdbdbd' }}>
                                    <div className={style.rankingBar}>
                                      <span className={style.percentageBar} style={{ width: `${bestStore.to_standard_percentage * 91}%`, backgroundColor: '#4472c4' }}></span>
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span>{`${(bestStore.to_standard_percentage * 100).toFixed(0)}%`}</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td style={{ width: '15%', textAlign: 'center' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                      <span className={`${style.delta} ${bestStoreDelta > 0 ? style.deltaPositive : bestStoreDelta < 0 ? style.deltaNegative : style.deltaZero}`}>
                                        {`${bestStoreDelta > 0 ? '+' : bestStoreDelta < 0 ? '-' : ''}${Math.abs(bestStoreDelta).toFixed(0)}%`}
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          ) : (
                            null
                          )
                        }
                        {
                          index === 0 ? (
                            <div style={{ width: '100%', borderBottom: '3px solid black', marginTop: '40px' }}></div>
                          ) : (
                            null
                          )
                        }
                        <table className={style.rankingTable} style={{ marginTop: '50px' }}>
                          <tbody>
                            {
                              ranking.map((store, index2) => {
                                return store.pjAverage ?
                                  <tr key={index2} className={`${style.rankingRow} ${style.podiumRow}`}>
                                    <td style={{ width: '10%' }}>
                                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                      </div>
                                    </td>
                                    <td style={{ width: '20%', textAlign: 'right', paddingRight: '20px', lineHeight: '1' }}>
                                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '-90px' }}>
                                        <img style={{ width: '200px' }} src={papa_johns_logo} alt='Papa Johns' /><span>{getCorrectUiText('Average', language)}</span>
                                      </div>
                                    </td>
                                    <td style={{ borderLeft: '3px solid #bdbdbd' }}>
                                      <div className={style.rankingBar}>
                                        <span className={style.percentageBar} style={{ width: `${store.to_standard_percentage * 91}%`, backgroundColor: '#089675' }}></span>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                          <span>{`${(store.to_standard_percentage * 100).toFixed(0)}%`}</span>
                                        </div>
                                      </div>
                                    </td>
                                    <td style={{ width: '15%', textAlign: 'center' }}>
                                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                      </div>
                                    </td>
                                  </tr>
                                  :
                                  <tr key={index2} className={`${style.rankingRow} ${style.podiumRow}`}>
                                    <td style={{ width: '10%' }}>
                                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        {
                                          index === 0 && store.index < 3 ? (
                                            <img src={store.index === 0 ? firstPlace : store.index === 1 ? secondPlace : store.index === 2 ? thirdPlace : null} alt='' style={{ height: '75px' }} />
                                          ) : (
                                            null
                                          )
                                        }
                                      </div>
                                    </td>
                                    <td style={{ width: '20%', textAlign: 'right', paddingRight: '20px', lineHeight: '1' }}>
                                      {store.name}
                                    </td>
                                    <td style={{ borderLeft: '3px solid #bdbdbd' }}>
                                      <div className={style.rankingBar}>
                                        <span className={style.percentageBar} style={{ width: `${store.to_standard_percentage * 91}%` }}></span>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                          <span>{`${(store.to_standard_percentage * 100).toFixed(0)}%`}</span>
                                        </div>
                                      </div>
                                    </td>
                                    <td style={{ width: '15%', textAlign: 'center' }}>
                                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <span className={`${style.delta} ${store.delta > 0 ? style.deltaPositive : store.delta < 0 ? style.deltaNegative : style.deltaZero}`}>
                                          {`${store.delta > 0 ? '+' : store.delta < 0 ? '-' : ''}${Math.abs(store.delta).toFixed(0)}%`}
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                      <img className={style.kwaliSeal} src={logoBlack} alt='kwali' />
                    </div>
                  })
                }
                {
                  shiftPerformance.map((ranking, index) => {
                    return <div className={`${style.reportPage} pdfPage`} style={{ paddingRight: '4%', paddingLeft: '4%' }} key={index}>
                      {
                        index === 0 ? (
                          <React.Fragment>
                            <span className={style.pageTitle}>{getCorrectUiText('Shifts performance', language)}</span>
                            <span className={style.disclaimer}>{getCorrectUiText('(% Pizzas to standard - Shift change at 5:00 PM)', language)}</span>
                            <div className={style.layout1legend}>
                              <div className={style.layout1legendItem}><span className={style.layout1legendColorIndicator} style={{ backgroundColor: 'white' }}>{getCorrectUiText('No Data', language)}</span></div>
                              <div className={style.layout1legendItem}><span className={style.layout1legendColorIndicator} style={{ backgroundColor: '#ffc5c5' }}>0% - 40%</span></div>
                              <div className={style.layout1legendItem}><span className={style.layout1legendColorIndicator} style={{ backgroundColor: '#ffe699' }}>40% - 60%</span></div>
                              <div className={style.layout1legendItem}><span className={style.layout1legendColorIndicator} style={{ backgroundColor: '#c6e0b4' }}>60% - 90%</span></div>
                              <div className={style.layout1legendItem}><span className={style.layout1legendColorIndicator} style={{ backgroundColor: '#00b050', color: 'white' }}>90% - 100%</span></div>
                            </div>
                          </React.Fragment>
                        ) : (
                          null
                        )
                      }
                      <div className={style.ranking}>
                        {
                          ranking.filter(store => !store.pjAverage).map((store, index) => {
                            return <div key={index} className={style.layout1} style={{ marginBottom: '50px' }}>
                              <span className={style.layout1Title} style={{ fontSize: '30px', lineBreak: 'anywhere' }}>{store.name}</span>
                              <table className={style.layout1Table}>
                                <tbody>
                                  <tr>
                                    <th style={{ backgroundColor: 'white' }}></th>
                                    <th>{getCorrectUiText('Sunday', language).slice(0, 2)}</th>
                                    <th>{getCorrectUiText('Monday', language).slice(0, 2)}</th>
                                    <th>{getCorrectUiText('Tuesday', language).slice(0, 2)}</th>
                                    <th>{getCorrectUiText('Wednesday', language).slice(0, 2)}</th>
                                    <th>{getCorrectUiText('Thursday', language).slice(0, 2)}</th>
                                    <th>{getCorrectUiText('Friday', language).slice(0, 2)}</th>
                                    <th>{getCorrectUiText('Saturday', language).slice(0, 2)}</th>
                                  </tr>
                                  <tr className={style.borderedRow}>
                                    <td style={{ border: 'none', fontSize: '30px' }}>{getCorrectUiText('Lunch', language)}</td>
                                    {
                                      ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((weekday, index) => {
                                        let weekdayData = store.to_standard_by_shift[weekday];
                                        let toStandardPercentageAM = weekdayData.to_standard_AM / weekdayData.count_AM;
                                        return <td key={index} style={{ backgroundColor: weekdayData.count_AM === 0 ? 'white' : (toStandardPercentageAM > 0.9 ? '#00b050' : toStandardPercentageAM > 0.6 ? '#c6e0b4' : toStandardPercentageAM > 0.4 ? '#ffe699' : toStandardPercentageAM >= 0 ? '#ffc5c5' : 'white') }}></td>
                                      })
                                    }
                                  </tr>
                                  <tr className={style.borderedRow}>
                                    <td style={{ border: 'none', fontSize: '30px' }}>{getCorrectUiText('Dinner', language)}</td>
                                    {
                                      ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((weekday, index) => {
                                        let weekdayData = store.to_standard_by_shift[weekday];
                                        let toStandardPercentagePM = weekdayData.to_standard_PM / weekdayData.count_PM;
                                        return <td key={index} style={{ backgroundColor: weekdayData.count_PM === 0 ? 'white' : (toStandardPercentagePM > 0.9 ? '#00b050' : toStandardPercentagePM > 0.6 ? '#c6e0b4' : toStandardPercentagePM > 0.4 ? '#ffe699' : toStandardPercentagePM >= 0 ? '#ffc5c5' : 'white') }}></td>
                                      })
                                    }
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          })
                        }
                      </div>
                      <img className={style.kwaliSeal} src={logoBlack} alt='kwali' />
                    </div>
                  })
                }
                <div className={`${style.reportPage} pdfPage`} style={{ paddingRight: '4%', paddingLeft: '4%' }} >
                  <span className={style.pageTitle}>{getCorrectUiText('Stores evolution', language)}</span>
                  <span className={style.disclaimer}>{getCorrectUiText('(% Pizzas to standard | Chart)', language)}</span>
                  <div className={style.chartsWrapper} style={{ marginTop: '20px' }}>
                    <div className={style.charts}>
                      <div
                        className={style.storesEvolutionChart}
                        style={{ paddingLeft: Object.keys(presentStores).length > STORES_EVOLUTION_PAGE_MAX_STORES ? '0' : `calc((100% - 25.5%) / ${reportByMonth.length} / 2 + 25.5% - 80px - 55px)`, paddingRight: Object.keys(presentStores).length > STORES_EVOLUTION_PAGE_MAX_STORES ? '0' : `calc((100% - 25.5%) / ${reportByMonth.length} / 2 - 55px)` }}>
                        <div className={style.plot} style={{ width: '100%', border: 'none' }}>
                          <Plot
                            data={
                              getStoresOrder(presentStores).map((store, index) => {
                                return {
                                  type: 'scatter',
                                  x: reportByMonth.map(item => getCorrectUiText(item.month, language).slice(0, 3)),
                                  y: reportByMonth.map(item => { return Object.keys(item.stores).length === 0 || item.stores[store] === undefined || item.stores[store].count === 0 ? undefined : item.stores[store].to_standard_percentage }),
                                  text: reportByMonth.map(item => { return Object.keys(item.stores).length === 0 || item.stores[store] === undefined || item.stores[store].count === 0 ? undefined : `<span style="font-weight: bold">${(item.stores[store].to_standard_percentage * 100).toFixed(0)}</span>` }),
                                  name: presentStores[store].name,
                                  mode: 'markers',
                                  marker: {
                                    symbol: SYMBOLS[index % SYMBOLS.length],
                                    size: 10,
                                    color: COLORS[index % COLORS.length],
                                    line: {
                                      color: COLORS[index % COLORS.length],
                                      width: 2
                                    }
                                  },
                                  textfont: {
                                    size: 25,
                                  }
                                }
                              }).concat([{
                                type: 'scatter',
                                x: reportByMonth.map(item => getCorrectUiText(item.month, language).slice(0, 3)),
                                y: getCompanyAvg().map(val => (val * 100).toFixed(0)),
                                text: getCompanyAvg().map(val => `<span style="font-weight: bold">${(val * 100).toFixed(0)}</span>`),
                                name: 'Company Average',
                                mode: 'markers',
                                marker: {
                                  size: 3,
                                  color: 'black',
                                },
                                textfont: {
                                  size: 25,
                                }
                              }]).concat(storesEvolutionTrace(reportByMonth, presentStores))
                            }
                            layout={{
                              height: Object.keys(presentStores).length > STORES_EVOLUTION_PAGE_MAX_STORES ? 1800 : 1400,
                              autosize: true,
                              margin: {
                                t: 80,
                                l: 80,
                                r: 0,
                                b: 40
                              },
                              yaxis: {
                                automargin: true,
                                tickformat: ',.0%',
                                showline: false,
                                showticklabels: true,
                                showgrid: false,
                                zeroline: true,
                                range: [0, 1.05],
                                rangemode: 'tozero',
                                tickfont: {
                                  size: 25
                                }
                              },
                              xaxis: {
                                showgrid: false,
                                tickfont: {
                                  size: 25
                                }
                              },
                              paper_bgcolor: 'rgba(255,255,255,1)',
                              plot_bgcolor: 'rgba(255,255,255,1)',
                              showlegend: false,

                            }}
                            config={{ displayModeBar: false }}
                            useResizeHandler={true}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    Object.keys(presentStores).length > STORES_EVOLUTION_PAGE_MAX_STORES ? (
                      null
                    ) : (
                      <div>
                        <table className={style.storesEvolutionTable}>
                          <tbody>
                            {
                              getStoresOrder(presentStores).map((store, index) => {
                                return <tr key={index}>
                                  <td> <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '5px',
                                    fontWeight: 'normal',
                                    textAlign: 'left'
                                  }}><Plot
                                      data={[
                                        {
                                          x: [0],
                                          y: [0],
                                          mode: 'markers',
                                          marker: {
                                            symbol: SYMBOLS[index % SYMBOLS.length],
                                            size: 10,
                                            color: COLORS[index % COLORS.length],
                                            line: {
                                              width: 2,
                                              color: COLORS[index % COLORS.length],
                                            },
                                          },
                                        }
                                      ]}
                                      layout={{
                                        width: 20,
                                        height: 20,
                                        showlegend: false,
                                        xaxis: {
                                          showgrid: false,
                                          zeroline: false,
                                          showticklabels: false
                                        },
                                        yaxis: {
                                          showgrid: false,
                                          zeroline: false,
                                          showticklabels: false
                                        },
                                        margin: { l: 0, r: 0, t: 0, b: 0 },
                                      }}
                                      config={{ displayModeBar: false }}
                                    /><span style={{
                                      backgroundColor: COLORS[index % COLORS.length],
                                      width: '40px',
                                      minWidth: '40px',
                                      height: '5px',
                                      display: 'block',
                                      marginLeft: '-20px'
                                    }}></span>
                                    {
                                      bestStore.name.includes(presentStores[store].name) ? (
                                        `${pjAverage.to_standard_percentage ? bestStoreCompany + ' - ' : ''}${presentStores[store].name} (${getCorrectUiText(`Best ${pjAverage.to_standard_percentage ? 'PJ ' : ''}Store`, language)})`
                                      ) : (
                                        presentStores[store].name
                                      )
                                    }
                                  </div></td>
                                  {
                                    reportByMonth.map(item => { return Object.keys(item.stores).length === 0 || item.stores[store] === undefined || item.stores[store].count === 0 ? undefined : (item.stores[store].to_standard_percentage * 100).toFixed(0) }).map((val, index2) => {
                                      return <td
                                        style={{
                                          width: `calc((100% - 25.5%) / ${reportByMonth.length})`,
                                          backgroundColor: colorVariation(reportByMonth.map(item => { return Object.keys(item.stores).length === 0 || item.stores[store] === undefined || item.stores[store].count === 0 ? undefined : (item.stores[store].to_standard_percentage * 100).toFixed(0) }), index2)
                                        }} key={index2}>{val !== undefined ? `${val}%` : ''}</td>
                                    })
                                  }
                                </tr>
                              })
                            }
                            <tr>
                              <td> <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '5px'
                              }}><span style={{
                                background: 'linear-gradient(to right, black 0px, black 25%, white 25%, white 37.5%, black 37.5%, black 62.5%, white 62.5%, white 75%, black 75%, black 100%)',
                                width: '45px',
                                height: '5px',
                                display: 'block',
                              }}></span>{getCorrectUiText('Company Average', language)}</div></td>
                              {
                                getCompanyAvg().map((val, index) => {
                                  return <td key={index} style={{ backgroundColor: colorVariation(getCompanyAvg(), index) }}>{val !== undefined ? `${(val * 100).toFixed(0)}%` : ''}</td>
                                })
                              }
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )
                  }
                  <img className={style.kwaliSeal} src={logoBlack} alt='kwali' />
                </div>
                {
                  Object.keys(presentStores).length > STORES_EVOLUTION_PAGE_MAX_STORES ? (
                    <div className={`${style.reportPage} pdfPage`} style={{ paddingRight: '4%', paddingLeft: '4%' }} >
                      <span className={style.disclaimer}>{getCorrectUiText('(% Pizzas to standard | Table)', language)}</span>
                      <br />
                      <br />
                      <div>
                        <table className={style.storesEvolutionTable}>
                          <tbody>
                            <tr>
                              <td></td>
                              {
                                reportByMonth.map((item, index) => {
                                  return <td key={index}>{getCorrectUiText(item.month, language).slice(0, 3)}</td>
                                })
                              }
                            </tr>
                            {
                              getStoresOrder(presentStores).map((store, index) => {
                                return <tr key={index}>
                                  <td> <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '5px',
                                    fontWeight: 'normal',
                                    textAlign: 'left'
                                  }}><Plot
                                      data={[
                                        {
                                          x: [0],
                                          y: [0],
                                          mode: 'markers',
                                          marker: {
                                            symbol: SYMBOLS[index % SYMBOLS.length],
                                            size: 10,
                                            color: COLORS[index % COLORS.length],
                                            line: {
                                              width: 2,
                                              color: COLORS[index % COLORS.length],
                                            },
                                          },
                                        }
                                      ]}
                                      layout={{
                                        width: 20,
                                        height: 20,
                                        showlegend: false,
                                        xaxis: {
                                          showgrid: false,
                                          zeroline: false,
                                          showticklabels: false
                                        },
                                        yaxis: {
                                          showgrid: false,
                                          zeroline: false,
                                          showticklabels: false
                                        },
                                        margin: { l: 0, r: 0, t: 0, b: 0 },
                                      }}
                                      config={{ displayModeBar: false }}
                                    /><span style={{
                                      backgroundColor: COLORS[index % COLORS.length],
                                      width: '40px',
                                      minWidth: '40px',
                                      height: '5px',
                                      display: 'block',
                                      marginLeft: '-20px'
                                    }}></span>
                                    {
                                      bestStore.name.includes(presentStores[store].name) ? (
                                        `${pjAverage.to_standard_percentage ? bestStoreCompany + ' - ' : ''}${presentStores[store].name} (${getCorrectUiText(`Best ${pjAverage.to_standard_percentage ? 'PJ ' : ''}Store`, language)})`
                                      ) : (
                                        presentStores[store].name
                                      )
                                    }
                                  </div></td>
                                  {
                                    reportByMonth.map(item => { return Object.keys(item.stores).length === 0 || item.stores[store] === undefined || item.stores[store].count === 0 ? undefined : (item.stores[store].to_standard_percentage * 100).toFixed(0) }).map((val, index2) => {
                                      return <td
                                        style={{
                                          width: `calc((100% - 25.5%) / ${reportByMonth.length})`,
                                          backgroundColor: colorVariation(reportByMonth.map(item => { return Object.keys(item.stores).length === 0 || item.stores[store] === undefined || item.stores[store].count === 0 ? undefined : (item.stores[store].to_standard_percentage * 100).toFixed(0) }), index2)
                                        }} key={index2}>{val !== undefined ? `${val}%` : ''}</td>
                                    })
                                  }
                                </tr>
                              })
                            }
                            <tr>
                              <td> <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '5px'
                              }}><span style={{
                                background: 'linear-gradient(to right, black 0px, black 25%, white 25%, white 37.5%, black 37.5%, black 62.5%, white 62.5%, white 75%, black 75%, black 100%)',
                                width: '45px',
                                height: '5px',
                                display: 'block',
                              }}></span>{getCorrectUiText('Company Average', language)}</div></td>
                              {
                                getCompanyAvg().map((val, index) => {
                                  return <td key={index} style={{ backgroundColor: colorVariation(getCompanyAvg(), index) }}>{val !== undefined ? `${(val * 100).toFixed(0)}%` : ''}</td>
                                })
                              }
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <img className={style.kwaliSeal} src={logoBlack} alt='kwali' />
                    </div>
                  ) : (
                    null
                  )
                }
                <div className={`${style.reportPage} pdfPage`} style={{ paddingRight: '4%', paddingLeft: '4%' }} >
                  <span className={style.pageTitle}>{getCorrectUiText('Ranking of the most common pizza problems', language)}</span>
                  <span className={style.disclaimer}>{getCorrectUiText('(% of times each attribute appeared)', language)}</span>
                  <div className={style.ranking}>
                    {
                      problems.map((problem, index) => {
                        return <div key={index} className={style.layout1} style={{ marginBottom: `${problems.length > 6 ? 0 : 50}px` }}>
                          <span className={style.layout1Title}>{PROBLEMS[problem.id].name[language]}</span>
                          <div className={style.layout1RightCol}>
                            <img src={problem.icon} alt='icon' className={style.layout1RightColIcon} />
                            <div className={style.layout1RightColPercentageBar} style={{ width: `${(problem.percentage / mainProblem.percentage) * 80}%` }}>
                              {
                                problem.percentage / mainProblem.percentage > 0.2 ? (
                                  <span className={style.layout1RightColPercentageBarInnerLabel}>{`${(problem.percentage * 100).toFixed(0)}%`}</span>
                                ) : (
                                  null
                                )
                              }
                            </div>
                            {
                              problem.percentage / mainProblem.percentage <= 0.2 ? (
                                <span className={style.layout1RightColPercentageBarOuterLabel}>{`${(problem.percentage * 100).toFixed(0)}%`}</span>
                              ) : (
                                null
                              )
                            }
                          </div>
                        </div>
                      })
                    }
                  </div>
                  <img className={style.kwaliSeal} src={logoBlack} alt='kwali' />
                </div>
                <div className={`${style.reportPage} pdfPage`} style={{ paddingRight: '4%', paddingLeft: '4%' }} >
                  <span className={style.pageTitle}>{getCorrectUiText('Evolution of the most common pizza problems', language)}</span>
                  <span className={style.disclaimer}>{getCorrectUiText('(monthly average attribute occurrences per store)', language)}</span>
                  <div className={style.chartsWrapper} style={{ marginTop: '20px' }}>
                    <div className={style.charts}>
                      <div className={style.storesEvolutionChart} style={{ paddingLeft: `calc((100% - 25.5%) / ${reportByMonthCurrentCompany.length} / 2 + 25.5% - 80px - 55px)`, paddingRight: `calc((100% - 25.5%) / ${reportByMonthCurrentCompany.length} / 2 - 55px)` }}>
                        <div className={style.plot} style={{ width: '100%', border: 'none' }}>
                          <Plot
                            data={
                              Object.keys(PROBLEMS).sort().map((problem, index) => {
                                return {
                                  type: 'scatter',
                                  x: reportByMonthCurrentCompany.map(item => getCorrectUiText(item.month, language).slice(0, 3)),
                                  y: reportByMonthCurrentCompany.map(item => getProblemAvg(problem, item)),
                                  text: reportByMonthCurrentCompany.map(item => `<span style="font-weight: bold">${getProblemAvg(problem, item)}</span>`),
                                  name: PROBLEMS[problem].name,
                                  mode: 'markers',
                                  marker: {
                                    symbol: SYMBOLS[(SYMBOLS.length - 1) - index],
                                    size: 13,
                                    color: COLORS[(COLORS.length - 1) - index],
                                    line: {
                                      color: COLORS[(COLORS.length - 1) - index],
                                      width: 4
                                    }
                                  },
                                  textfont: {
                                    size: 25,
                                  }
                                }
                              }).concat(problemsEvolutionTrace(reportByMonthCurrentCompany))
                            }
                            layout={{
                              height: 1400,
                              autosize: true,
                              margin: {
                                t: 80,
                                l: 80,
                                r: 0,
                                b: 40
                              },
                              yaxis: {
                                automargin: true,
                                showline: false,
                                showticklabels: true,
                                showgrid: false,
                                zeroline: true,
                                rangemode: 'tozero',
                                tickfont: {
                                  size: 25
                                }
                              },
                              xaxis: {
                                showgrid: false,
                                tickfont: {
                                  size: 25
                                }
                              },
                              paper_bgcolor: 'rgba(255,255,255,1)',
                              plot_bgcolor: 'rgba(255,255,255,1)',
                              showlegend: false,

                            }}
                            config={{ displayModeBar: false }}
                            useResizeHandler={true}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <table className={style.storesEvolutionTable}>
                      <tbody>
                        {
                          Object.keys(PROBLEMS).sort((a, b) => a > b ? 1 : a < b ? -1 : 0).map((problem, index) => {
                            return <tr key={index}>
                              <td> <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '5px',
                                fontWeight: 'normal'
                              }}><Plot
                                  data={[
                                    {
                                      x: [0],
                                      y: [0],
                                      mode: 'markers',
                                      marker: {
                                        symbol: SYMBOLS[(SYMBOLS.length - 1) - index],
                                        size: 10,
                                        color: COLORS[(COLORS.length - 1) - index],
                                        line: {
                                          width: 2,
                                          color: COLORS[(COLORS.length - 1) - index],
                                        },
                                      },
                                    }
                                  ]}
                                  layout={{
                                    width: 20,
                                    height: 20,
                                    showlegend: false,
                                    xaxis: {
                                      showgrid: false,
                                      zeroline: false,
                                      showticklabels: false
                                    },
                                    yaxis: {
                                      showgrid: false,
                                      zeroline: false,
                                      showticklabels: false
                                    },
                                    margin: { l: 0, r: 0, t: 0, b: 0 },
                                  }}
                                  config={{ displayModeBar: false }}
                                /><span style={{
                                  backgroundColor: COLORS[(COLORS.length - 1) - index],
                                  width: '40px',
                                  height: '5px',
                                  display: 'block',
                                  marginLeft: '-20px'
                                }}></span>{PROBLEMS[problem].name[language]}</div></td>
                              {
                                reportByMonthCurrentCompany.map((item, index2) => {
                                  return <td style={{ width: `calc((100% - 25.5%) / ${reportByMonthCurrentCompany.length})` }} key={index2}>{getProblemAvg(problem, item) !== undefined ? `${getProblemAvg(problem, item)}` : ''}</td>
                                })
                              }
                            </tr>
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                  <img className={style.kwaliSeal} src={logoBlack} alt='kwali' />
                </div>
                {
                  shiftPerformance.map((ranking, index) => {
                    if (foodCostOpportunities) {
                      return <div className={`${style.reportPage} pdfPage`} style={{ paddingRight: '4%', paddingLeft: '4%' }} key={index}>
                        {
                          index === 0 ? (
                            <React.Fragment>
                              <span className={style.pageTitle}>{getCorrectUiText('Food cost opportunities', language)}</span>
                              <span className={style.disclaimer}>{getCorrectUiText('(% Pizzas that exceed standard portions)', language)}</span>
                              <div className={style.layout1legend}>
                                <div className={style.layout1legendItem}><span className={style.layout1legendColorIndicator} style={{ backgroundColor: 'white' }}>{getCorrectUiText('No Data', language)}</span></div>
                                <div className={style.layout1legendItem}><span className={style.layout1legendColorIndicator} style={{ backgroundColor: '#00b050' }}>0% - 5%</span></div>
                                <div className={style.layout1legendItem}><span className={style.layout1legendColorIndicator} style={{ backgroundColor: '#ffe699' }}>5% - 10%</span></div>
                                <div className={style.layout1legendItem}><span className={style.layout1legendColorIndicator} style={{ backgroundColor: '#ffc5c5' }}>{'> 10%'}</span></div>
                              </div>
                            </React.Fragment>
                          ) : (
                            null
                          )
                        }
                        <div className={style.ranking}>
                          {
                            ranking.filter(store => !store.pjAverage).map((store, index) => {
                              return <div key={index} className={style.layout1} style={{ marginBottom: '50px' }}>
                                <span className={style.layout1Title} style={{ fontSize: '30px', lineBreak: 'anywhere' }}>{store.name}</span>
                                <table className={style.layout1Table}>
                                  <tbody>
                                    <tr>
                                      <th style={{ backgroundColor: 'white' }}></th>
                                      <th>{getCorrectUiText('Sunday', language).slice(0, 2)}</th>
                                      <th>{getCorrectUiText('Monday', language).slice(0, 2)}</th>
                                      <th>{getCorrectUiText('Tuesday', language).slice(0, 2)}</th>
                                      <th>{getCorrectUiText('Wednesday', language).slice(0, 2)}</th>
                                      <th>{getCorrectUiText('Thursday', language).slice(0, 2)}</th>
                                      <th>{getCorrectUiText('Friday', language).slice(0, 2)}</th>
                                      <th>{getCorrectUiText('Saturday', language).slice(0, 2)}</th>
                                    </tr>
                                    <tr className={style.borderedRow}>
                                      <td style={{ border: 'none', fontSize: '30px' }}>{getCorrectUiText('Lunch', language)}</td>
                                      {
                                        ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((weekday, index) => {
                                          let weekdayData = store.over_pepperoni_by_shift[weekday]['AM'];
                                          let val = weekdayData.over / (weekdayData.over + weekdayData.valid);
                                          return <td key={index} style={{ backgroundColor: weekdayData.over + weekdayData.valid === 0 ? 'white' : (val > 0.1 ? '#ffc5c5' : val > 0.05 ? '#ffe699' : '#00b050') }}></td>
                                        })
                                      }
                                    </tr>
                                    <tr className={style.borderedRow}>
                                      <td style={{ border: 'none', fontSize: '30px' }}>{getCorrectUiText('Dinner', language)}</td>
                                      {
                                        ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((weekday, index) => {
                                          let weekdayData = store.over_pepperoni_by_shift[weekday]['PM'];
                                          let val = weekdayData.over / (weekdayData.over + weekdayData.valid);
                                          return <td key={index} style={{ backgroundColor: weekdayData.over + weekdayData.valid === 0 ? 'white' : (val > 0.1 ? '#ffc5c5' : val > 0.05 ? '#ffe699' : '#00b050') }}></td>
                                        })
                                      }
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            })
                          }
                        </div>
                        <img className={style.kwaliSeal} src={logoBlack} alt='kwali' />
                      </div>
                    }
                    else {
                      return null;
                    }
                  })
                }
                <div className={`${style.reportPage} pdfPage`} style={{ paddingRight: '4%', paddingLeft: '4%' }} >
                  <span className={style.pageTitle}>{getCorrectUiText('Featured images', language)}</span>
                  <span className={style.disclaimer}>{getCorrectUiText('(Examples of to standard and not to standard pizzas)', language)}</span>
                  <div className={style.cards}>
                    {
                      cards.map((card, index) => {
                        return (
                          <div className={style.card} key={index}>
                            <img onLoad={() => handleImageLoad(index)} src={`${process.env.REACT_APP_IMAGE_SERVER}/api/static/${card.img_url}`} alt='Pizza' />
                            <div className={style.cardInfo}>
                              <div className={style.cardLegend}>{``}</div>
                              <img src={card.standard === 'to_standard' ? check : mark} alt='Pizza' className={style.cardIcon} />
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                  <img className={style.kwaliSeal} src={logoBlack} alt='kwali' />
                </div>
              </div>
            ) : (
              null
            )
          }
        </div>
      </div>
    </LoadingScreen>
  )
}

export default MonthlyReportPreview;