import React, { useState, useEffect, useCallback } from 'react';
import { FetchService } from '../../../../services/Api';
import LoadingScreen from '../../../common/loadingScreen/LoadingScreen';
import style from './SmartChange.module.css';
import ModalWrapper from '../../../common/modalWrapper/ModalWrapper';
import check from '../../../../vectors/check.svg';
import mark from '../../../../vectors/mark.svg';
import { PROBLEMS } from '../../Utils';
import Select from '../../../common/select/Select';

const SmartChange = (props) => {
  const {
    report,
    successCallback,
    endCallback,
    pizzaToBeReplaced,
    pizzaCandidates,
    reportProblems
  } = props;
  const [loading, setLoading] = useState(false);
  const [displayPizzaCandidates, setDisplayPizzaCandidates] = useState(false);
  const [replaceSelected, setReplaceSelected] = useState(undefined);
  const [mainProblemSelected, setMainProblemSelected] = useState(undefined);

  useEffect(() => {
    if (pizzaToBeReplaced !== undefined) setDisplayPizzaCandidates(true);
    else setDisplayPizzaCandidates(false);
  }, [pizzaToBeReplaced])

  useEffect(() => {
    if (!displayPizzaCandidates) {
      endCallback();
      setReplaceSelected(undefined);
      setMainProblemSelected(undefined);
    }
  }, [displayPizzaCandidates, endCallback])

  useEffect(() => {
    if (report === undefined || replaceSelected === undefined) return;
    if (replaceSelected.standard === 'to_standard' || mainProblemSelected !== undefined || replaceSelected.scoreless) {
      let replace = {
        ...replaceSelected,
        ...(mainProblemSelected ? {
          main_problem: mainProblemSelected,
        } : {})
      };
      let target = pizzaToBeReplaced;
      let requestConf = {
        endpoint: `pizza_tv/report/smart_change/`,
        method: 'POST',
        dataType: 'JSON',
        data: {
          report_id: report._id,
          new: replace,
          old: target
        }
      }

      setLoading(true);
      FetchService(requestConf).then(
        (response) => {
          if (response.status === 200) {
            successCallback(replace, target);
          }
        },
        (err) => {
        }
      ).catch(err => console.log(err)).finally((res) => {
        setLoading(false);
        setReplaceSelected(undefined);
        setMainProblemSelected(undefined);
        endCallback();
      });
    }
    //eslint-disable-next-line
  }, [replaceSelected, mainProblemSelected, pizzaToBeReplaced, endCallback])

  const mainProblemDropdown = useCallback((card) => {
    return {
      optionsList: reportProblems.map(id => { return {
        name: PROBLEMS[id] && PROBLEMS[id].name && PROBLEMS[id].name.en ? PROBLEMS[id].name.en : id,
        value: id,
        selected: Array.isArray(card.mainProblem) ? (card.mainProblem.filter(problem => problem === id).length > 0 ? true : false) : (card.mainProblem === id ? true : false),
        disabled: id=='overbaked',
      } }),
      callback: (name, selection) => {
        setMainProblemSelected(selection.map(option => option.value));
      }
    }
  }, [reportProblems])

  return (
    <LoadingScreen loading={loading}>
      {
        displayPizzaCandidates ? (
          <ModalWrapper
            customWrapperCss={{ width: '1500px', maxHeight: '900px', overflowY: 'scroll' }}
            allScreen={true}
            fitContent={false}
            display={displayPizzaCandidates}
            setDisplay={setDisplayPizzaCandidates} >
            {
              replaceSelected === undefined || replaceSelected.standard === 'to_standard' ? (
                <div className={style.cards} >
                  {
                    pizzaCandidates.map((card, index) => {
                      return (
                        <div className={style.card} key={index}>
                          <img onClick={() => { setReplaceSelected(card) }} src={`${process.env.REACT_APP_IMAGE_SERVER}/api/static/${card.img_url}`} alt='Pizza' />
                          <span className={style.cardID}>{`S${index + 1}`}</span>
                          <div className={style.cardInfo}>
                            <div className={style.cardLegend}>{`${card.weekday.slice(0, 3)} - ${card.timeStamp || (card.shift === 'AM' ? 'Lunch' : 'Dinner')}`}</div>
                            {
                              pizzaToBeReplaced && pizzaToBeReplaced.standard !== undefined ? (
                                <img src={card.standard === 'to_standard' ? check : mark} alt='Pizza' className={style.cardIcon} />
                              ) : (
                                null
                              )
                            }
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              ) : (
                replaceSelected.standard === 'not_to_standard' ? (
                  <div style={{ width: '100%', height: '700px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
                      <img style={{ width: '600px' }} src={`${process.env.REACT_APP_IMAGE_SERVER}/api/static/${replaceSelected.img_url}`} alt='Pizza' />
                      <div style={{ width: '400px' }}>
                        <Select
                          autoWidth={true}
                          name='Main Problem'
                          multiple={true}
                          checkboxes={false}
                          {...mainProblemDropdown(replaceSelected)}
                          extraActions={true} />
                      </div>
                    </div>
                  </div>
                ) : (
                  null
                )
              )
            }
          </ModalWrapper>
        ) : (
          null
        )
      }
    </LoadingScreen>
  )
}

export default SmartChange;