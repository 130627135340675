import React, { useState, useEffect, useCallback } from 'react';
import { FetchService } from '../../../services/Api';
import LoadingScreen from '../../common/loadingScreen/LoadingScreen';
import Header from '../../header/Header';
import style from './OperationControlCenter.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faMinus } from '@fortawesome/free-solid-svg-icons';

const COLS = [
  'Company',
  'New Stores',
  'Stores',
  'Complete',
  '% Printed',
  '% Sent',
  'LW Score',
  'Print Score',
  'Delta vs LW Score',
  'Delta LW vs 4LW Score',
  'Highest Score increase',
  'Highest Score decrease',
  '% Stores increasing',
  '% Stores decreasing'
]
const PATH_BY_COL = {
  'Company': ['name'],
  'New Stores': ['new'],
  'Stores': ['stores', 'length'],
  'Complete': ['complete'],
  '% Printed': ['printed_percentage'],
  '% Sent': ['sent_percentage'],
  'LW Score': ['lw', 'avg'],
  'Print Score': ['cw', 'avg'],
  'Delta vs LW Score': ['delta_lw'],
  'Delta LW vs 4LW Score': ['delta_5w'],
  'Highest Score increase': ['best_delta', 'delta'],
  'Highest Score decrease': ['worst_delta', 'delta'],
  '% Stores increasing': ['positive_delta_percentage'],
  '% Stores decreasing': ['negative_delta_percentage'],
}

const OperationControlCenter = (props) => {
  const {
    whenSelectingWeek,
    selectedWeek
  } = props;
  const [loading, setLoading] = useState(false);
  const [OCCData, setOCCData] = useState(undefined);
  const [refreshing, setRefreshing] = useState(true);
  const [rows, setRows] = useState(undefined);
  const [sortInfo, setSortInfo] = useState({});
  const [colToSort, setColToSort] = useState(undefined);

  const getOCCData = useCallback(() => {
    if (selectedWeek === undefined) return;
    setLoading(true);
    let requestConf = {
      endpoint: `pizza_tv/occ/week_stamp/${selectedWeek.getUTCDate()}-${selectedWeek.getUTCMonth() + 1}-${selectedWeek.getUTCFullYear()}/`,
      method: 'GET'
    }
    FetchService(requestConf)
      .then(response => {
        setOCCData(response.data);
      })
      .catch(err => console.log(err))
      .finally(() => {
        setLoading(false);
        setRefreshing(false);
      });
  }, [selectedWeek])

  useEffect(() => {
    if (selectedWeek === undefined) return;
    getOCCData();
  }, [selectedWeek])

  useEffect(() => {
    if (!refreshing) return;
    getOCCData();
  }, [refreshing])

  const refresh = useCallback(() => {
    setOCCData(undefined);
    setRefreshing(true);
  }, [])

  useEffect(() => {
    if (OCCData === undefined) return;
    setRows(OCCData.resume_by_company);
  }, [OCCData])

  useEffect(() => {
    if (OCCData === undefined || colToSort === undefined) return;
    let temp = JSON.parse(JSON.stringify(OCCData.resume_by_company))
    if (sortInfo[colToSort].path.length === 1) {
      let key1 = sortInfo[colToSort].path[0];
      let direction = sortInfo[colToSort].direction;
      temp.sort((a, b) => {
        if (a[key1] === undefined && b[key1] !== undefined) return direction;
        if (a[key1] !== undefined && b[key1] === undefined) return direction * -1;
        return a[key1] > b[key1] ? sortInfo[colToSort].direction * -1 : a[key1] < b[key1] ? sortInfo[colToSort].direction : 0;
      });
    }
    else if (sortInfo[colToSort].path.length === 2) {
      let key1 = sortInfo[colToSort].path[0];
      let key2 = sortInfo[colToSort].path[1];
      let direction = sortInfo[colToSort].direction;
      temp.sort((a, b) => {
        if (a[key1][key2] === undefined && b[key1][key2] !== undefined) return direction;
        if (a[key1][key2] !== undefined && b[key1][key2] === undefined) return direction * -1;
        return a[key1][key2] > b[key1][key2] ? direction * -1 : a[key1][key2] < b[key1][key2] ? direction : 0;
      });
    }
    setRows(temp);
  }, [OCCData, sortInfo, colToSort])

  const saveSortInfo = useCallback((col, path) => {
    if (col in sortInfo) {
      let info = sortInfo[col];
      if (colToSort === col) info['direction'] = info['direction'] * -1
      setSortInfo(prevData => {
        return {
          ...prevData,
          [col]: info
        }
      })
    }
    else {
      setSortInfo(prevData => {
        return {
          ...prevData,
          [col]: {
            path: path,
            direction: 1
          }
        }
      })
    }
    setColToSort(col);
  }, [sortInfo, colToSort])

  return (
    <LoadingScreen loading={loading}>
      <div className={style.wrapper}>
        <Header
          whenSelectingWeek={whenSelectingWeek}
          selectedWeek={selectedWeek}
        />
        <button className={style.refreshButton} onClick={() => refresh()}>Refresh</button>
        <div className={style.pageContent}>
          {
            OCCData && rows ? (
              <div>
                <table style={{ borderCollapse: 'collapse', marginBottom: '15px' }}>
                  <tbody>
                    <tr style={{ backgroundColor: '#00225d', color: 'white' }}>
                      <th style={{ textAlign: 'center', padding: '10px', border: '1px solid darkgrey' }}>Total Stores</th>
                      <th style={{ textAlign: 'center', padding: '10px', border: '1px solid darkgrey' }}>% Printed</th>
                      <th style={{ textAlign: 'center', padding: '10px', border: '1px solid darkgrey' }}>% Sent</th>
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'left', padding: '5px', border: '1px solid darkgrey' }}>{OCCData.resume_by_company.map(company => company.stores.length).reduce((acc, cur) => acc + cur, 0)}</td>
                      <td style={{
                        textAlign: 'center',
                        padding: '5px',
                        border: '1px solid darkgrey'
                      }}>{`${OCCData.printed_percentage ? (OCCData.printed_percentage * 100).toFixed(0).concat('%') : '0%'}`}</td>
                      <td style={{ textAlign: 'center', padding: '5px', border: '1px solid darkgrey' }}>{`${OCCData.sent_percentage ? (OCCData.sent_percentage * 100).toFixed(0).concat('%') : '0%'}`}</td>
                    </tr>
                  </tbody>
                </table>
                <table style={{ borderCollapse: 'collapse' }}>
                  <tbody>
                    <tr style={{ backgroundColor: '#00225d', color: 'white' }}>
                      {
                        COLS.map((col, index) => {
                          return <th key={index} style={{ textAlign: 'center', padding: '10px', border: '1px solid darkgrey', backgroundColor: [6, 7, 8, 9].includes(index) ? '#0070c5' : 'inherit' }}>
                            {col}
                            <button
                              style={{ background: 'none', border: 'none' }}
                              onClick={() => { saveSortInfo(index, PATH_BY_COL[col]) }}>
                              <FontAwesomeIcon style={{ color: 'white' }} icon={!sortInfo || !sortInfo[index] || colToSort !== index? faMinus : (sortInfo[index].direction === 1 ? faArrowUp : faArrowDown)} />
                            </button>
                          </th>
                        })
                      }
                    </tr>
                    {
                      rows.map((company, index) => {
                        return <tr key={index}>
                          <td style={{ textAlign: 'left', padding: '5px', border: '1px solid darkgrey' }}>{company.name}</td>
                          <td style={{
                            textAlign: 'center',
                            padding: '5px',
                            border: '1px solid darkgrey',
                            backgroundColor: company.new ? '#e68037' : 'white',
                            color: company.new ? 'white' : 'black'
                          }}>{`${company.new ? 'NEW' : 'STABLE'}`}</td>
                          <td style={{ textAlign: 'center', padding: '5px', border: '1px solid darkgrey' }}>{company.stores.length}</td>
                          <td style={{
                            textAlign: 'center',
                            padding: '5px',
                            color: 'white',
                            border: '1px solid darkgrey',
                            backgroundColor: company.complete === company.stores.length ? '#00b450' : '#ffc000'
                          }}>{company.complete}</td>
                          <td style={{
                            textAlign: 'center',
                            padding: '5px',
                            color: 'white',
                            border: '1px solid darkgrey',
                            backgroundColor: company.printed_percentage === 1 ? '#00b450' : company.printed_percentage > 0 ? '#ffc000' : '#f90201'
                          }}>{`${company.printed_percentage ? (company.printed_percentage * 100).toFixed(0).concat('%') : '0%'}`}</td>
                          <td style={{ textAlign: 'center', padding: '5px', border: '1px solid darkgrey' }}>{`${company.sent_percentage ? (company.sent_percentage * 100).toFixed(0).concat('%') : '0%'}`}</td>
                          {
                            company.lw.count > 0 ? (
                              <td style={{ textAlign: 'center', padding: '5px', border: '1px solid darkgrey' }}>{`${(company.lw.avg * 100).toFixed(0)}%`}</td>
                            ) : (
                              <td style={{ textAlign: 'center', padding: '5px', border: '1px solid darkgrey' }}>-</td>
                            )
                          }
                          {
                            company.cw.count > 0 ? (
                              <td style={{ textAlign: 'center', padding: '5px', border: '1px solid darkgrey' }}>{`${(company.cw.avg * 100).toFixed(0)}%`}</td>
                            ) : (
                              <td style={{ textAlign: 'center', padding: '5px', border: '1px solid darkgrey' }}>-</td>
                            )
                          }
                          {
                            company.delta_lw ? (
                              <td style={{
                                textAlign: 'center',
                                padding: '5px',
                                border: '1px solid darkgrey',
                                backgroundColor: Math.abs(company.delta_lw) > 0.06 ? '#f90201' : Math.abs(company.delta_lw) > 0.03 ? '#ffc000' : 'inherit',
                                color: Math.abs(company.delta_lw) > 0.03 ? 'white' : 'inherit'
                              }}>{`${company.delta_lw > 0 ? '+' : ''}${((company.delta_lw) * 100).toFixed(0)}pp`}</td>
                            ) : (
                              <td style={{ textAlign: 'center', padding: '5px', border: '1px solid darkgrey' }}>-</td>
                            )
                          }
                          {
                            company.delta_5w ? (
                              <td style={{
                                textAlign: 'center',
                                padding: '5px',
                                border: '1px solid darkgrey',
                                backgroundColor: Math.abs(company.delta_5w) > 0.06 ? '#f90201' : Math.abs(company.delta_5w) > 0.03 ? '#ffc000' : 'inherit',
                                color: Math.abs(company.delta_5w) > 0.03 ? 'white' : 'inherit'
                              }}>{`${company.delta_5w > 0 ? '+' : ''}${((company.delta_5w) * 100).toFixed(0)}pp`}</td>
                            ) : (
                              <td style={{ textAlign: 'center', padding: '5px', border: '1px solid darkgrey' }}>-</td>
                            )
                          }
                          {
                            company.best_delta.store ? (
                              <td style={{
                                textAlign: 'center',
                                padding: '5px',
                                border: '1px solid darkgrey',
                                backgroundColor: Math.abs(company.best_delta.delta) > 0.06 ? '#f90201' : Math.abs(company.best_delta.delta) > 0.03 ? '#ffc000' : 'inherit',
                                color: Math.abs(company.best_delta.delta) > 0.03 ? 'white' : 'inherit'
                              }}>{`${company.best_delta.delta > 0 ? '+' : ''}${((company.best_delta.delta) * 100).toFixed(0)}pp`}</td>
                            ) : (
                              <td style={{ textAlign: 'center', padding: '5px', border: '1px solid darkgrey' }}>-</td>
                            )
                          }
                          {
                            company.worst_delta.store ? (
                              <td style={{
                                textAlign: 'center',
                                padding: '5px',
                                border: '1px solid darkgrey',
                                backgroundColor: Math.abs(company.worst_delta.delta) > 0.06 ? '#f90201' : Math.abs(company.worst_delta.delta) > 0.03 ? '#ffc000' : 'inherit',
                                color: Math.abs(company.worst_delta.delta) > 0.03 ? 'white' : 'inherit'
                              }}>{`${company.worst_delta.delta > 0 ? '+' : ''}${((company.worst_delta.delta) * 100).toFixed(0)}pp`}</td>
                            ) : (
                              <td style={{ textAlign: 'center', padding: '5px', border: '1px solid darkgrey' }}>-</td>
                            )
                          }
                          {
                            'positive_delta_percentage' in company ? (
                              <td style={{
                                textAlign: 'center',
                                padding: '5px',
                                border: '1px solid darkgrey',
                                color: 'white',
                                backgroundColor: company.positive_delta_percentage > 0.5 ? '#f90201' : company.positive_delta_percentage > 35 ? '#ffc000' : '#00b450'
                              }}>{`${((company.positive_delta_percentage) * 100).toFixed(0)}%`}</td>
                            ) : (
                              <td style={{ textAlign: 'center', padding: '5px', border: '1px solid darkgrey' }}>-</td>
                            )
                          }
                          {
                            'negative_delta_percentage' in company ? (
                              <td style={{
                                textAlign: 'center',
                                padding: '5px',
                                border: '1px solid darkgrey',
                                color: 'white',
                                backgroundColor: company.negative_delta_percentage > 0.5 ? '#f90201' : company.negative_delta_percentage > 0.35 ? '#ffc000' : '#00b450'
                              }}>{`${((company.negative_delta_percentage) * 100).toFixed(0)}%`}</td>
                            ) : (
                              <td style={{ textAlign: 'center', padding: '5px', border: '1px solid darkgrey' }}>-</td>
                            )
                          }
                        </tr>
                      })
                    }
                  </tbody>
                </table>
              </div>
            ) : (
              null
            )
          }
        </div>
      </div>
    </LoadingScreen>
  )
}

export default OperationControlCenter;