import React, { useState, useEffect, useCallback } from 'react';
import style from './Summary.module.css'
import WeekShift from './weekShift/WeekShift';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { DAYS, PROBLEMS } from '../../../Utils';

const Summary = (props) => {
  const {
    showOnReport,
    featuredBest,
    featuredWorst,
    weekdayShift,
    specialties,
    changeStep,
    fillReport,
    scorelessReport,
    state,
    reportSpecialtiesDefinition,
    reportStructure,
    mainProblem,
    totalNotToStandard,
    classifiedPizzas,
    trackOfDays,
    whenSelectingDay,
    updateVisibleToStandard,
    visibleToStandardPercentage } = props;
  const [complete, setComplete] = useState(false);
  const [reds, setReds] = useState(false);
  const [daysReviewedOptions, setDaysReviewedOptions] = useState(false);
  const [visibleToStandard, setVisibleToStandard] = useState(undefined);
  const REPORT_PIZZAS_SCORELESS_GOAL = (reportStructure.REPORT_PIZZAS_SCORELESS_GOAL_PER_SHIFT ?? 10) * 2 * 7;
  const DEFAULT_REPORT_PIZZAS_GOAL_PER_SPECIALTY = Math.round((reportStructure.REPORT_PIZZAS_GOAL ?? 30) / Object.keys(specialties ?? [1]).length);

  useEffect(() => {
    if (specialties === undefined) return;
    let val = {};
    for (let specialty of Object.keys(specialties)) val[specialty] = '';
    setVisibleToStandard(visibleToStandardPercentage !== undefined && Object.keys(visibleToStandardPercentage).length > 0 ? visibleToStandardPercentage : val);
  }, [visibleToStandardPercentage, specialties])

  useEffect(() => {
    if (weekdayShift === undefined) return;
    for (let shift of Object.keys(weekdayShift)) {
      for (let weekday of Object.keys(weekdayShift[shift])) {
        if (weekdayShift[shift][weekday] > reportStructure.REPORT_PIZZAS_SCORELESS_GOAL_PER_SHIFT) {
          setReds(true);
          return;
        }
      }
    }
    setReds(false);
  }, [weekdayShift, reportStructure])

  useEffect(() => {
    if (!scorelessReport) {
      if (showOnReport <= reportStructure.REPORT_PIZZAS_GOAL && featuredBest === 1 && featuredWorst === 1 && classifiedPizzas === totalNotToStandard) {
        setComplete(true);
      }
      else {
        setComplete(false);
      }
    }
    else {
      if (showOnReport <= REPORT_PIZZAS_SCORELESS_GOAL) {
        setComplete(true);
      }
      else {
        setComplete(false);
      }
    }
  }, [showOnReport, featuredWorst, featuredBest, scorelessReport, reportStructure, REPORT_PIZZAS_SCORELESS_GOAL, classifiedPizzas, totalNotToStandard])

  const nexState = useCallback(() => {
    if (!scorelessReport) {
      if (showOnReport === reportStructure.REPORT_PIZZAS_GOAL || (showOnReport < reportStructure.REPORT_PIZZAS_GOAL && window.confirm(`You are about to continue with less than ${reportStructure.REPORT_PIZZAS_GOAL} selected pizzas, do you want to continue?`))) {
        changeStep('scoreless data selection');
      }
    }
    else {
      if (showOnReport === REPORT_PIZZAS_SCORELESS_GOAL) {
        if (reds) {
          if (window.confirm(`You are about to continue with shifts with more than ${reportStructure.REPORT_PIZZAS_SCORELESS_GOAL_PER_SHIFT} selected pizzas, do you want to continue?`)) {
            changeStep('complete');
          }
        }
        else {
          changeStep('complete');
        }
      }
      else {
        if (reds) {
          if (window.confirm(`You are about to continue with shifts with more than ${reportStructure.REPORT_PIZZAS_SCORELESS_GOAL_PER_SHIFT} selected pizzas, do you want to continue?`)) {
            changeStep('complete');
          }
        }
        else if (showOnReport < REPORT_PIZZAS_SCORELESS_GOAL && window.confirm(`You are about to continue with less than ${REPORT_PIZZAS_SCORELESS_GOAL} selected pizzas, do you want to continue?`)) {
          changeStep('complete');
        }
      }
    }
  }, [changeStep, showOnReport, scorelessReport, reds, REPORT_PIZZAS_SCORELESS_GOAL, reportStructure])

  useEffect(() => {
    if ([undefined, null].includes(trackOfDays)) return;
    setDaysReviewedOptions(DAYS.map(day => ({ name: day, value: day, selected: trackOfDays[day].checked ? true : false })));
  }, [trackOfDays])

  const updateVisibleData = useCallback((specialty, value) => {
    setVisibleToStandard(prevData => ({
      ...(prevData ?? {}),
      [specialty]: isNumber(value) ? Number(value) / 100 : value
    }));
  }, [])

  const isNumber = (value) => {
    return value !== '' && !isNaN(value) && typeof Number(value) === "number";
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', float: 'right', top: `${daysReviewedOptions ? '10%' : '17%'}`, position: 'sticky', height: '850px', marginTop: '-100px', overflowY: 'auto' }}>
      <div className={style.wrapper}>
        <div>
          {
            Object.keys(specialties).length > 2 && visibleToStandard !== undefined && Object.keys(visibleToStandard).length > 0 && !scorelessReport ? (
              <div>
                {
                  specialties !== undefined ? (
                    Object.keys(specialties).map((specialty, index) => {
                      return (
                        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                          <span><small>{specialty}</small></span><input type='text' value={isNumber(visibleToStandard[specialty]) ? (visibleToStandard[specialty] * 100).toFixed(0) : visibleToStandard[specialty]} onClick={e => e.target.select()} onChange={e => {
                            let value = e.target.value;
                            value = value.replace(/[^0-9]/g, '');
                            if (value.length > 3) return
                            updateVisibleData(specialty, value);
                          }} /><span>{(specialties[specialty].standardPercentage * 100).toFixed(0)}%</span>
                        </div>
                      )
                    })
                  ) : (
                    null
                  )
                }
                <button className={style.button} style={{ width: '100%', marginBottom: '10px' }} onClick={() => {
                  updateVisibleToStandard(visibleToStandard, Object.keys(specialties));
                }}>Send</button>
              </div>
            ) : (
              null
            )
          }
        </div>
        {
          scorelessReport ? (
            null
          ) : (
            <React.Fragment>
              <span className={style.title}>On Report Summary</span>
              {
                specialties !== undefined ? (
                  Object.keys(specialties).map((specialty, index) => {
                    if (specialty !== 'total') {
                      return (
                        <div className={style.row} key={index}>
                          <span>{specialty} <FontAwesomeIcon style={{ transform: 'rotate(45deg)', color: 'red' }} icon={faPlus} /></span><span>{`${specialties[specialty].notToStandardOnReport}/${Math.round(((specialties[specialty].notToStandard / specialties[specialty].notMaybeToStandard) || 0) * (reportSpecialtiesDefinition && reportSpecialtiesDefinition[specialty] ? reportSpecialtiesDefinition[specialty].show_goal || DEFAULT_REPORT_PIZZAS_GOAL_PER_SPECIALTY : DEFAULT_REPORT_PIZZAS_GOAL_PER_SPECIALTY))} (${specialties[specialty].notToStandard}/${specialties[specialty].notMaybeToStandard})`}</span>
                        </div>
                      )
                    }
                    else {
                      return null
                    }
                  })
                ) : (
                  null
                )
              }
              <div className={style.separator} style={{ marginTop: '5px' }}></div>
              {
                specialties !== undefined ? (
                  Object.keys(specialties).map((specialty, index) => {
                    if (specialty !== 'total') {
                      return (
                        <div className={style.row} key={index}>
                          <span>{specialty}</span><span>{`${specialties[specialty].onReport}/${(reportSpecialtiesDefinition && reportSpecialtiesDefinition[specialty] ? reportSpecialtiesDefinition[specialty].show_goal || DEFAULT_REPORT_PIZZAS_GOAL_PER_SPECIALTY : DEFAULT_REPORT_PIZZAS_GOAL_PER_SPECIALTY)}`}</span>
                        </div>
                      )
                    }
                    else {
                      return null
                    }
                  })
                ) : (
                  null
                )
              }
              <div className={style.row}>
                <span>Featured Best</span><span>{`${featuredBest}/${1}`}</span>
              </div>
              <div className={style.row}>
                <span>Featured Worst</span><span>{`${featuredWorst}/${1}`}</span>
              </div>
            </React.Fragment>
          )
        }
        <span className={style.subtitle}>Weekday/Shift</span>
        <WeekShift weekdayShift={weekdayShift} scoreless={scorelessReport} REPORT_PIZZAS_SCORELESS_GOAL_PER_SHIFT={reportStructure.REPORT_PIZZAS_SCORELESS_GOAL_PER_SHIFT} />
        <br />
        <div className={style.condition}>
          <span>Pizzas Selected</span><span>{`(${showOnReport}/${scorelessReport ? REPORT_PIZZAS_SCORELESS_GOAL : reportStructure.REPORT_PIZZAS_GOAL})`}</span>
        </div>
        {
          scorelessReport ? (
            null
          ) : (
            <React.Fragment>
              <span className={style.title}>Problem Count</span>
              <React.Fragment>
                {
                  reportStructure.REPORT_PROBLEMS.map((problem, index) => {
                    return <div key={index} className={style.row}>
                      <span>{PROBLEMS[problem] && PROBLEMS[problem].name ? PROBLEMS[problem].name.en : problem}</span><span>{`${mainProblem[problem] ?? 0}`}</span>
                    </div>
                  })
                }
                <br />
                <div className={style.condition}>
                  <span>Pizzas Classified</span><span>{`(${classifiedPizzas}/${totalNotToStandard})`}</span>
                </div>
              </React.Fragment>
            </React.Fragment>
          )
        }
        <div className={style.buttons}>
          <button
            className={style.button}
            onClick={() => {
              state === 'data selection' ? (
                window.location = '/pizza-tv-explorer'
              ) : (
                changeStep('data selection')
              )
            }
            }>{'< Exit'}</button>
          <button className={style.button} disabled={complete ? false : true} onClick={() => nexState()}>{'Continue >'}</button>
        </div>
        <React.Fragment>
          <br />
          <button className={style.button} style={{ width: 'inherit' }} onClick={() => {
            if (fillReport === undefined) return;
            fillReport()
          }}>Fill Show on Report</button>
        </React.Fragment>
      </div >
      <div className={style.wrapper} style={{ alignItems: 'normal' }}>
        <span className={style.title} style={{textAlign: 'center'}}>Track of Days</span>
        <table>
          <tbody>
            <tr>
              <th><small>Day</small></th>
              <th><small>Fetched</small></th>
              <th><small>Reviewed</small></th>
            </tr>
            {
              trackOfDays ? (
                DAYS.map((day, index) => {
                  return <tr key={index}>
                    <td>{day}</td>
                    <td style={{textAlign: 'center'}}>
                      <input
                        checked={trackOfDays[day].fetched}
                        type='checkbox'
                        disabled={trackOfDays[day].fetched}
                        onChange={(e) => {
                          e.preventDefault();
                          whenSelectingDay('fetched', day, index, e.target.checked);
                        }} /></td>
                    <td style={{textAlign: 'center'}}>
                      <input
                        checked={trackOfDays[day].checked}
                        type='checkbox'
                        onChange={(e) => {
                          e.preventDefault();
                          whenSelectingDay('checked', day, index, e.target.checked);
                        }} /></td>
                  </tr>
                })
              ) : (
                null
              )
            }
          </tbody>
        </table>
      </div>
    </div >
  )
}

export default Summary;