import React, { useState, useEffect, useCallback } from 'react';
import { FetchService } from '../../../services/Api';
import Header from '../../header/Header';
import LoadingScreen from '../../common/loadingScreen/LoadingScreen';
import style from './ReportControl.module.css';
import Plot from 'react-plotly.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faQuestion, faCheck, faRecycle, faDownload, faCrown, faEye } from '@fortawesome/free-solid-svg-icons';
import ModalWrapper from '../../common/modalWrapper/ModalWrapper';
import check from '../../../vectors/check.svg';
import mark from '../../../vectors/mark.svg';
import { getReportName, reportPizzasToCards, getSimilarPizzas, DAYS, SHIFTS, COLORS, SYMBOLS, getXAxis } from '../Utils';
import stressTestReport from './StressTest';
import SmartChange from '../common/smartChange/SmartChange';

const noSeleniumActivity = "No report printing in progress";
const seleniumActivity = "Report printing in progress";

const ReportControl = (props) => {
  const {
    whenSelectingCompany,
    selectedCompany,
    selectedCompanyPrettyName,
    whenSelectingWeek,
    selectedWeek,
    whenSelectingVenue,
    selectedVenue,
    selectedWeekNumber,
    whenPrettyNames,
    venuesPrettyName
  } = props;
  const [loading, setLoading] = useState(false);
  const [venues, setVenues] = useState(undefined);
  const [report, setReport] = useState(undefined);
  const [storesEvolution, setStoresEvolution] = useState(undefined);
  const [deltas, setDeltas] = useState(undefined);
  const [oldReportsToStandard, setOldReportsToStandard] = useState([]);
  const [cards, setCards] = useState([]);
  const [cardsScoreless, setCardsScoreless] = useState({});
  const [featureBest, setFeatureBest] = useState(undefined);
  const [featureWorst, setFeatureWorst] = useState(undefined);
  const [displayInspectedPicture, setDisplayInspectedPicture] = useState(false);
  const [inspectedPicture, setInspectedPicture] = useState(undefined);
  const [reportByVenue, setReportByVenue] = useState([]);
  const [selectedStore, setSelectedStore] = useState(undefined);
  const [refreshing, setRefreshing] = useState(false);
  const [lrAverageScore, setLrAverageScore] = useState(0);
  const [lrAverageDelta, setLrAverageDelta] = useState(0);
  const [stressTest, setStressTest] = useState(undefined);
  const [storesEvolutionEndpointReady, setStoresEvolutionEndpointReady] = useState(true);
  const [currentReportEndpointReady, setCurrentReportEndpointReady] = useState(true);
  const [reportSpecialties, setReportSpecialties] = useState(undefined);
  const [lrScores, setLrScores] = useState({});
  const [simulatorData, setSimulatorData] = useState({});
  const [pizzaCandidates, setPizzaCandidates] = useState([]);
  const [pizzaToBeReplaced, setPizzaToBeReplaced] = useState(undefined);
  const [specialtiesDefinition, setSpecialtiesDefinition] = useState(undefined);
  const [reportStructure, setReportStructure] = useState(undefined);
  const [storesEvolutionOrder, setStoresEvolutionOrder] = useState(undefined);
  const [chartsXAxis, setChartsXAxis] = useState([]);
  const [storesEvolutionWeekAvg, setStoresEvolutionWeekAvg] = useState(undefined);
  const [rVenues, setRVenues] = useState([]);
  const [seleniumState, setSeleniumState] = useState(undefined);
  const [seleniumQueue, setSeleniumQueue] = useState([]);
  const [seleniumStateString, setSeleniumStateString] = useState(noSeleniumActivity);
  const [seleniumStateString2, setSeleniumStateString2] = useState('');

  useEffect(() => {
    setSelectedStore(undefined);
    whenSelectingVenue(undefined, [{ value: undefined }]);
  }, [selectedCompany, whenSelectingVenue])

  useEffect(() => {
    setSelectedStore(selectedVenue);
  }, [selectedVenue])

  useEffect(() => {
    setRVenues([]);
    setStoresEvolutionOrder(undefined);
    setReport(undefined);
    setOldReportsToStandard([]);
    setStoresEvolution(undefined);
    setReportByVenue([]);
    setCards([]);
    setCardsScoreless({});
    setFeatureBest(undefined);
    setFeatureWorst(undefined);
    setStressTest(undefined);
    setReportSpecialties(undefined);
    setChartsXAxis([]);
    setStoresEvolutionWeekAvg(undefined);
    setSeleniumState(undefined);
    setSeleniumStateString(noSeleniumActivity);
    setSeleniumStateString2('');
  }, [selectedWeek, selectedStore, selectedCompany])

  useEffect(() => {
    if (seleniumState !== undefined) return;
    let requestConf = {
      endpoint: `pizza_tv/selenium/state/`,
      method: 'GET'
    }
    FetchService(requestConf)
      .then(response => {
        setSeleniumState(response.data)
      })
      .catch(err => console.log(err));
  }, [seleniumState])

  useEffect(() => {
    if (seleniumState === undefined) return;
    setSeleniumQueue((seleniumState.reports || []).map(r => r._id));
  }, [seleniumState])

  useEffect(() => {
    if (seleniumState === undefined || Object.keys(seleniumState).length === 0 || seleniumState.index === seleniumState.reports.length) return;
    let [hours, minutes] = seleniumState.start.split(':');
    let now = new Date();
    now.setUTCHours(hours);
    now.setUTCMinutes(minutes);
    setSeleniumStateString(`${seleniumActivity}:`);
    setSeleniumStateString2(`${seleniumState.reports[seleniumState.index].pk} | ${seleniumState.reports[seleniumState.index].report_name} | ${seleniumState.reports.length - 1} pending (${now.getHours()}:${now.getMinutes()})`);
  }, [seleniumState])

  useEffect(() => {
    if (selectedCompany === undefined) return;
    setLoading(true);
    let requestConf = {
      endpoint: `dashboard/venues-by-company/${selectedCompany}`,
      method: 'GET'
    }

    FetchService(requestConf).then(
      (response) => {
        setVenues(response.data.venues ?? undefined)
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err)).finally(() => { setLoading(false) });
  }, [selectedCompany])

  useEffect(() => {
    if (selectedStore === undefined || selectedWeek === undefined) return;
    let requestConf = {
      endpoint: `pizza_tv/report_control/venue_pk/${selectedStore}/week_stamp/${selectedWeek.getUTCDate()}-${selectedWeek.getUTCMonth() + 1}-${selectedWeek.getUTCFullYear()}/`,
      method: 'GET'
    }
    setCurrentReportEndpointReady(false);
    FetchService(requestConf).then(
      (response) => {
        setReport(response.data);
        setCurrentReportEndpointReady(true);
      },
      (err) => {
        console.log(err)
      }
    ).catch(err => console.log(err))
  }, [selectedStore, selectedWeek])

  useEffect(() => {
    if (reportStructure !== undefined) return;
    let requestConf = {
      endpoint: `pizza_tv/report_structure/`,
      method: 'GET'
    }
    setLoading(true);
    FetchService(requestConf)
      .then(response => setReportStructure(response.data))
      .catch(err => console.log(err));
  }, [reportStructure])

  useEffect(() => {
    if (report === undefined || reportStructure === undefined) return;
    let d = reportPizzasToCards(report, report.scoreless_report ? true : false, true);
    if (!report.scoreless_report) {
      setCards(d.cards);
    }
    setCardsScoreless(d.cardsScoreless);
    setReportSpecialties(d.specialties ?? [undefined]);
    setFeatureBest(d.featureBest);
    setFeatureWorst(d.featureWorst);
    if (report.pizzas.length > 0) setStressTest(stressTestReport(report, d.specialties ?? [undefined], reportStructure));
    setChartsXAxis(getXAxis(report));
  }, [report, reportStructure, selectedWeekNumber, selectedWeek])

  const showPizzaCandidates = useCallback((card, type) => {
    setPizzaToBeReplaced(card);
    setPizzaCandidates(getSimilarPizzas(card, report, type));
  }, [report])

  useEffect(() => {
    if (selectedStore === undefined || selectedCompany === undefined || selectedWeek === undefined) return;
    let requestConf = {
      endpoint: `pizza_tv/stores_evolution/`,
      method: 'GET',
      dataType: 'JSON',
      data: {
        report_control: 1,
        venue: selectedStore,
        franchise: selectedCompany,
        week_stamp: `${selectedWeek.getUTCDate()}-${selectedWeek.getUTCMonth() + 1}-${selectedWeek.getUTCFullYear()}`
      }
    }
    setStoresEvolutionEndpointReady(false);
    FetchService(requestConf).then(
      (response) => {
        setRVenues(response.data.r_venues);
        delete response.data.r_venues;
        setStoresEvolution(response.data);
        setStoresEvolutionEndpointReady(true);
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err))
    //eslint-disable-next-line
  }, [selectedStore, selectedWeek])

  useEffect(() => {
    if (currentReportEndpointReady === false || storesEvolutionEndpointReady === false) setLoading(true);
    if (currentReportEndpointReady && storesEvolutionEndpointReady) setLoading(false);
  }, [currentReportEndpointReady, storesEvolutionEndpointReady])

  useEffect(() => {
    if (storesEvolution === undefined || report === undefined || reportSpecialties === undefined || storesEvolution[report.venue] === undefined) return;
    let oldReportsWindowSize = report._id && !['complete', 'generated', 'delivered'].includes(report.state) ? 11 : 12;
    let currentVenueReports = storesEvolution[report.venue].reports.filter(r => ['complete', 'generated', 'delivered'].includes(r.state)).slice(-oldReportsWindowSize);
    let oldReportsToStandard = [];
    if (['complete', 'generated', 'delivered'].includes(report.state)) {
      oldReportsWindowSize = Math.min(11, currentVenueReports.length - 1);
    }
    else {
      oldReportsWindowSize = Math.min(12, currentVenueReports.length);
    }
    for (let report of currentVenueReports.slice(0, oldReportsWindowSize)) {
      let elem = {
        key: report.key,
        total: report.to_standard_percentage
      }
      for (let specialty of reportSpecialties) {
        if (specialty === undefined) continue;
        elem[specialty] = report.resume_by_specialty[specialty];
      }
      oldReportsToStandard.push(elem);
    }
    setOldReportsToStandard(oldReportsToStandard);
  }, [report, storesEvolution, reportSpecialties])

  useEffect(() => {
    if (storesEvolution === undefined || report === undefined || reportSpecialties === undefined) return;
    let inspectedWeekReports = Object.keys(storesEvolution).map(storeName => storesEvolution[storeName].reports.slice(-1)[0]);
    inspectedWeekReports = inspectedWeekReports.filter(r => r !== undefined && r.doc && r.doc.week === selectedWeekNumber);
    let deltas = {};
    let inspectedWeekStores = [];
    let reportByVenue = [...inspectedWeekReports];
    let lrAverageScore = 0;
    let lrAverageScoreCount = 0;
    let lrAverageDelta = 0;
    let lrAverageDeltaCount = 0;
    for (let i = 0; i < inspectedWeekReports.length; i++) {
      inspectedWeekStores.push(inspectedWeekReports[i].venue);
      let delta = '--%';
      let lrScore = '--';
      let dStyle = style.deltaZero;
      if (storesEvolution[inspectedWeekReports[i].venue] && storesEvolution[inspectedWeekReports[i].venue].reports.length > 1) {
        let lastTwoReports = storesEvolution[inspectedWeekReports[i].venue].reports.slice(-1);
        for (let j = storesEvolution[inspectedWeekReports[i].venue].reports.length - 2; j >= 0; j--) {
          if (['complete', 'generated', 'delivered'].includes(storesEvolution[inspectedWeekReports[i].venue].reports[j].state)) {
            lastTwoReports.unshift(storesEvolution[inspectedWeekReports[i].venue].reports[j]);
            break;
          }
        }
        if (lastTwoReports.length === 2) {
          if (lastTwoReports[1].state !== 'waiting_initialization') {
            lrAverageDeltaCount += 1;
            delta = Math.round((lastTwoReports[1].venue === report.venue ? report.standard.total : lastTwoReports[1].to_standard_percentage) * 100) - Math.round(lastTwoReports[0].to_standard_percentage * 100);
            lrAverageDelta += delta;
            dStyle = delta > 0 ? style.deltaPositive : delta < 0 ? style.deltaNegative : style.deltaZero;
          }
          lrScore = (lastTwoReports[0].to_standard_percentage * 100).toFixed(0);
          lrAverageScore += lastTwoReports[0].to_standard_percentage * 100;
          lrAverageScoreCount += 1;
          if (inspectedWeekReports[i].venue === selectedStore) {
            const lrScores = { total: Math.round(lastTwoReports[0].to_standard_percentage * 100) };
            for (const specialty of reportSpecialties) {
              if (specialty === undefined) continue;
              lrScores[specialty] = Math.round(lastTwoReports[0].resume_by_specialty[specialty] * 100);
            }
            setLrScores(lrScores);
          }
        }
      }
      if (delta !== '--%') {
        delta = `${delta > 0 ? '+' : delta < 0 ? '-' : ''}${Math.abs(delta)}%`;
      }
      deltas[inspectedWeekReports[i].venue] = { delta: delta, lrScore: `${lrScore}%`, dStyle: dStyle };
    }
    for (let venue of venues) {
      if (!inspectedWeekStores.includes(venue)) {
        if (storesEvolution[venue]) {
          let lastReport = storesEvolution[venue].reports.slice(-1);
          reportByVenue.push({
            state: 'non-existent',
            doc: { venue: venuesPrettyName[venue] },
            venue: venue
          })
          if (lastReport.length > 0) {
            lrAverageScore += lastReport[0].to_standard_percentage * 100;
            lrAverageScoreCount += 1;
          }
          deltas[venue] = { delta: '--%', lrScore: lastReport.length > 0 ? `${(lastReport[0].to_standard_percentage * 100).toFixed(0)}%` : '--%', dStyle: style.deltaZero };
        }
        else {
          reportByVenue.push({
            state: 'non-existent',
            doc: { venue: venuesPrettyName[venue] },
            venue: venue
          })
          deltas[venue] = { delta: '--%', lrScore: '--%', dStyle: style.deltaZero };
        }
      }
    }
    reportByVenue.sort((a, b) => venuesPrettyName[a.venue] < venuesPrettyName[b.venue] ? -1 : venuesPrettyName[a.venue] > venuesPrettyName[b.venue] ? 1 : 0);
    setReportByVenue(reportByVenue);
    setDeltas(deltas);
    setLrAverageScore(Math.round((lrAverageScore / lrAverageScoreCount) || 0));
    setLrAverageDelta(Math.round((lrAverageDelta / lrAverageDeltaCount) || 0));
  }, [storesEvolution, report, selectedStore, venues, venuesPrettyName, reportSpecialties, selectedWeekNumber])

  useEffect(() => {
    if (report === undefined || reportSpecialties === undefined) return;
    if (report.pizzas.length > 0) {
      const newSimulatorData = {};
      let simTotalCount = 0;
      let simTotalBads = 0;
      let simTotalGoods = 0;
      for (const specialty of reportSpecialties) {
        let specialtyBads = report.pizzas.filter(p => p.specialty === specialty && p.standard === 'not_to_standard').length;
        let specialtyGoods = report.pizzas.filter(p => p.specialty === specialty && p.standard === 'to_standard').length;
        let simCount = specialtiesDefinition && specialtiesDefinition[specialty] ? specialtiesDefinition[specialty].show_goal || 15 : 15;
        let simBads = Math.round(((specialtyBads / (specialtyBads + specialtyGoods)) || 0) * simCount);
        let simGoods = simCount - simBads;
        let simScore = Math.round(((simGoods / simCount) || 0) * 100);
        let simDelta = lrScores[specialty] ? simScore - lrScores[specialty] : '--';
        newSimulatorData[specialty] = {
          count: simCount,
          bads: simBads,
          goods: simGoods,
          score: simScore,
          delta: simDelta
        }
        simTotalCount += simCount;
        simTotalBads += simBads;
        simTotalGoods += simGoods;
      }
      newSimulatorData['total'] = {
        count: simTotalCount,
        bads: simTotalBads,
        goods: simTotalGoods,
        score: Math.round(((simTotalGoods / simTotalCount) || 0) * 100),
        delta: lrScores['total'] ? Math.round(((simTotalGoods / simTotalCount) || 0) * 100) - lrScores['total'] : '--'
      }
      setSimulatorData(newSimulatorData);
    }
  }, [report, lrScores, reportSpecialties, specialtiesDefinition])

  const updateSimData = useCallback((specialty, value) => {
    const simulatorDataCpy = { ...simulatorData };
    simulatorDataCpy[specialty].bads = value;
    simulatorDataCpy[specialty].goods = simulatorDataCpy[specialty].count - simulatorDataCpy[specialty].bads;
    simulatorDataCpy[specialty].score = Math.round(((simulatorDataCpy[specialty].goods / simulatorDataCpy[specialty].count) || 0) * 100);
    simulatorDataCpy[specialty].delta = lrScores[specialty] ? simulatorDataCpy[specialty].score - lrScores[specialty] : '--';
    let simTotalCount = 0;
    let simTotalBads = 0;
    let simTotalGoods = 0;
    for (const spec of reportSpecialties) {
      simTotalCount += simulatorDataCpy[spec].count;
      simTotalBads += simulatorDataCpy[spec].bads;
      simTotalGoods += simulatorDataCpy[spec].goods;
    }
    simulatorDataCpy['total'] = {
      count: simTotalCount,
      bads: simTotalBads,
      goods: simTotalGoods,
      score: Math.round(((simTotalGoods / simTotalCount) || 0) * 100),
      delta: lrScores['total'] ? Math.round(((simTotalGoods / simTotalCount) || 0) * 100) - lrScores['total'] : '--'
    }
    setSimulatorData(simulatorDataCpy);
  }, [simulatorData, reportSpecialties, lrScores])

  const initializeReport = useCallback((report, index) => {
    if (report.state !== 'non-existent') return;
    let requestConf = {
      endpoint: `pizza_tv/initialize_report/`,
      method: 'POST',
      dataType: 'JSON',
      data: {
        venue_pk: report.venue,
        week_stamp: `${selectedWeek.getUTCDate()}-${selectedWeek.getUTCMonth() + 1}-${selectedWeek.getUTCFullYear()}`,
        week_number: selectedWeekNumber,
        report_control: 1
      }
    }

    setLoading(true);
    FetchService(requestConf).then(
      (response) => {
        refresh();
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err)).finally((res) => setLoading(false));
  }, [selectedWeek, selectedWeekNumber])

  const initializeReports = useCallback(() => {
    let requestConf = {
      endpoint: `pizza_tv/initialize_reports/`,
      method: 'POST',
      dataType: 'JSON',
      data: {
        venues: reportByVenue.filter(r => r.state === 'non-existent').map(r => r.venue),
        week_stamp: `${selectedWeek.getUTCDate()}-${selectedWeek.getUTCMonth() + 1}-${selectedWeek.getUTCFullYear()}`,
        week_number: selectedWeekNumber,
      }
    }

    setLoading(true);
    FetchService(requestConf)
      .then(response => {
        let alertMsg = response.data.msg;
        if (response.data.fails) {
          for (let fail of response.data.fails) {
            alertMsg += `\n${fail.store}: ${fail.err}`;
          }
        }
        window.alert(alertMsg);
        refresh();
      })
      .catch(err => console.log(err))
      .finally((res) => setLoading(false));
  }, [selectedWeek, selectedWeekNumber, reportByVenue])

  const getDiffHours = (b) => {
    let now = new Date();
    b = new Date(b);
    let nowUtc0 = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
    let diffMs = nowUtc0 - b;
    let min = Math.floor((diffMs / (1000 * 60)) % 60);
    let hours = Math.floor((diffMs / (1000 * 60 * 60)) % 24);
    let res = `${hours < 10 ? '0' : ''}${hours}:${min < 10 ? '0' : ''}${min}`;
    return res;
  }

  const getStateConf = (state) => {
    let res = {
      'delivered': { symbol: 'S', style: { backgroundColor: '#e1eed8', color: '#54c903', fontWeight: 'bold' } },
      'generated': { symbol: 'P', style: { backgroundColor: '#d6e0f2', color: '#4472c4', fontWeight: 'bold' } },
      'complete': { symbol: 'C', style: { backgroundColor: '#fff2cc', color: '#f5bd19', fontWeight: 'bold' } },
      'scoreless data selection': { symbol: '2', style: { backgroundColor: '#faeecd', color: '#ffbe00', fontWeight: 'bold' } },
      'data selection': { symbol: '1', style: { backgroundColor: '#faeecd', color: '#ffbe00', fontWeight: 'bold' } },
      'waiting_initialization': { symbol: 'W', style: { backgroundColor: '#faeecd', color: '#ffbe00', fontWeight: 'bold' } },
      'non-existent': { symbol: '-', style: { backgroundColor: 'white', color: 'black', fontWeight: 'bold' } },
    }
    return res[state];
  }

  const refresh = () => {
    setSelectedStore(undefined);
    setRefreshing(true);
  }

  useEffect(() => {
    if (refreshing) setSelectedStore(selectedVenue);
    setRefreshing(false);
  }, [refreshing, selectedVenue])

  const sendAll = useCallback((reportsToBeDelivered = undefined, page = undefined, alertMsg = undefined) => {
    function finish() {
      window.alert(alertMsg);
      setLoading(false);
      refresh();
    }
    if (!reportsToBeDelivered) {
      setLoading(true);
      reportsToBeDelivered = reportByVenue.filter(report => report.state === 'generated');
      page = 0
    }
    const pageSize = 5;
    let reports = reportsToBeDelivered.map(r => ({ ...r, name: getReportName(new Date(r.week_stamp), venuesPrettyName[r.venue] || r.doc.venue, r.doc.franchise) })).slice(page * pageSize, page * pageSize + pageSize);
    if (reports.length === 0) {
      finish();
      return;
    }
    let requestConf = {
      endpoint: `pizza_tv/report/pdf_email/`,
      method: 'POST',
      dataType: 'JSON',
      data: {
        reports: reports,
      },
      customAlerts: {
        code: 504,
        alert: 'WARNING: The request is taking too long BUT the system is still sending mails. Wait some minutes and check again.'
      }
    }
    FetchService(requestConf)
      .then(response => {
        if (!alertMsg) {
          alertMsg = response.data.msg;
        }
        if (response.data.fails) {
          for (let fail of response.data.fails) {
            alertMsg += `\n${fail.report}: ${fail.err}`
          }
        }
        sendAll(reportsToBeDelivered, page + 1, alertMsg);
      })
      .catch(err => {
        console.log(err)
      })
  }, [reportByVenue, venuesPrettyName])

  const reportPreview = useCallback((report) => {
    if (!['generated', 'delivered'].includes(report.state)) return;
    let reportName = getReportName(new Date(report.week_stamp), venuesPrettyName[report.venue] || report.doc.venue, report.doc.franchise);
    setLoading(true);
    let requestConf = {
      endpoint: `pizza_tv/report_preview/`,
      method: 'GET',
      data: {
        report_name: reportName
      }
    }
    FetchService(requestConf)
      .then(response => {
        window.open(response.data.url, '_blank');
      })
      .catch(err => {
        console.log(err);
      })
      .finally(response => {
        setLoading(false);
      })
  }, [venuesPrettyName])

  const smartChangeSuccessCallback = useCallback((replace, target) => {
    let updateDict = JSON.parse(JSON.stringify(report));
    if (replace.scoreless === 0) {
      updateDict[replace.pizzasField][replace.index].featured = target.featured ?? 0;
      updateDict[replace.pizzasField][target.index].featured = 0;
      if (replace.main_problem) {
        updateDict[replace.pizzasField][replace.index].main_problem = replace.main_problem
      }
    }
    updateDict[replace.pizzasField][target.index].show_on_report = 0;
    updateDict[replace.pizzasField][replace.index].show_on_report = 1;
    setReport(updateDict);
    setCurrentReportEndpointReady(true);
  }, [report])

  const smartChangeEndCallback = useCallback(() => {
    setPizzaToBeReplaced(undefined);
  }, [])

  useEffect(() => {
    if (reportStructure === undefined || reportStructure.REPORT_SPECIALTIES_DEFINITION === undefined || specialtiesDefinition !== undefined) return;
    setSpecialtiesDefinition(reportStructure.REPORT_SPECIALTIES_DEFINITION);
  }, [reportStructure, specialtiesDefinition])

  useEffect(() => {
    if (storesEvolution === undefined || report === undefined) return;
    let ranking = Object.keys(storesEvolution).map(storeName => storesEvolution[storeName].reports.slice(-1)[0]);
    ranking = ranking.filter(r => r !== undefined && r.key.includes(report.doc ? report.doc.week : selectedWeekNumber));
    ranking.sort((a, b) => a.to_standard_percentage > b.to_standard_percentage ? -1 : a.to_standard_percentage < b.to_standard_percentage ? 1 : 0)
    let rankingStores = ranking.map(r => r.venue).filter(storeName => Object.keys(storesEvolution).includes(storeName));
    let sortedStoresEvolution = Object.keys(storesEvolution).sort((a, b) => storesEvolution[a].pretty_name > storesEvolution[b].pretty_name ? 1 : storesEvolution[a].pretty_name < storesEvolution[b].pretty_name ? -1 : 0);
    for (let storeName of sortedStoresEvolution) {
      if (!rankingStores.includes(storeName)) rankingStores.push(storeName);
    }
    setStoresEvolutionOrder(rankingStores.filter(store => rVenues.length > 0 ? rVenues.includes(store) : true));
  }, [storesEvolution, report, rVenues, selectedWeekNumber])

  const getYValues = useCallback((reports) => {
    let validReports = reports.filter(report => ['complete', 'generated', 'delivered'].includes(report.state));
    let yValues = [];
    for (let key of chartsXAxis) {
      let week = key.split(' ')[0]
      let year = key.split(' ')[1]
      let value = undefined;
      for (let report of validReports) {
        if (report.key === week && report.week_stamp.split('-')[0] === year) {
          value = report.to_standard_percentage;
          break;
        }
      }
      yValues.push(value);
    }
    return yValues;
  }, [chartsXAxis])

  const storesEvolutionTrace = (storesEvolution, chartsXAxis, storesEvolutionWeekAvg, storesEvolutionOrder) => {
    let traces = [];
    let stores = storesEvolutionOrder;
    for (let [index, store] of stores.entries()) {
      let validReports = storesEvolution[store] ? storesEvolution[store].reports.filter(report => ['complete', 'generated', 'delivered'].includes(report.state)) : [];
      for (let i = 1; i < validReports.length; i++) {
        if (chartsXAxis.map(key => key.split(' ')[0]).includes(validReports[i - 1].key) && chartsXAxis.map(key => key.split(' ')[0]).includes(validReports[i].key)) {
          traces.push({
            x: [validReports[i - 1].key, validReports[i].key],
            y: [validReports[i - 1].to_standard_percentage, validReports[i].to_standard_percentage],
            mode: 'lines',
            type: 'scatter',
            line: {
              color: COLORS[index % COLORS.length],
              width: 2
            }
          })
        }
      }
    }
    for (let i = 0, j = i + 1; j < storesEvolutionWeekAvg.length; i++, j++) {
      if (storesEvolutionWeekAvg[i] === undefined) continue;
      if (storesEvolutionWeekAvg[j] === undefined) {
        i--;
        continue;
      }
      traces.push({
        x: [chartsXAxis.map(key => key.split(' ')[0])[i], chartsXAxis.map(key => key.split(' ')[0])[j]],
        y: [storesEvolutionWeekAvg[i], storesEvolutionWeekAvg[j]],
        mode: 'lines',
        type: 'scatter',
        line: {
          dash: 'dash',
          color: 'black',
          width: 3
        },
      })
      i = j - 1
      j = i + 1;
    }
    return traces;
  }

  useEffect(() => {
    if (storesEvolution !== undefined) {
      let storesEvolutionWeekAvg = [];
      for (let key of chartsXAxis) {
        let week = key.split(' ')[0]
        let year = key.split(' ')[1]
        let avg = 0;
        let sum = 0;
        let count = 0;
        for (let storeName of Object.keys(storesEvolution).filter(storeName => rVenues.includes(storeName))) {
          let validReports = storesEvolution[storeName].reports.filter(report => ['complete', 'generated', 'delivered'].includes(report.state));
          for (let report of validReports) {
            if (report.key === week && report.week_stamp.split('-')[0] === year) {
              count += 1;
              sum += report.to_standard_percentage;
              avg = sum / count;
            }
          }
        }
        storesEvolutionWeekAvg.push(count > 0 ? avg : undefined);
      }
      setStoresEvolutionWeekAvg(storesEvolutionWeekAvg);
    }
  }, [storesEvolution, chartsXAxis, rVenues])

  const modifyPizzaCard = useCallback((card) => {
    let requestConf = {
      endpoint: `pizza_tv/report/update_featured/`,
      method: 'POST',
      dataType: 'JSON',
      data: {
        ...card,
        report_id: report._id,
        attribute: 'featured',
        value: 1,
        state: report.state,
        standard_scoreless_report: report.standard_scoreless_report
      }
    }

    setLoading(true);
    FetchService(requestConf)
      .then(response => {
        let updateDict = JSON.parse(JSON.stringify(report));
        for (let i = 0; i < updateDict.pizzas.length; ++i) {
          if (updateDict.pizzas[i].featured === 1 && card.standard === updateDict.pizzas[i].standard) updateDict.pizzas[i].featured = 0
        }
        updateDict.pizzas[card.index].featured = 1;
        setReport(updateDict);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }, [report])

  return (
    <LoadingScreen loading={loading}>
      <SmartChange
        report={report}
        successCallback={smartChangeSuccessCallback}
        endCallback={smartChangeEndCallback}
        pizzaToBeReplaced={pizzaToBeReplaced}
        pizzaCandidates={pizzaCandidates}
        reportProblems={reportStructure ? reportStructure.REPORT_PROBLEMS : undefined}
      ></SmartChange>
      {
        displayInspectedPicture ? (
          <ModalWrapper
            allScreen={true}
            fitContent={true}
            display={displayInspectedPicture}
            setDisplay={setDisplayInspectedPicture} >
            <div className={style.inspectedPictureWrapper}>
              <img className={style.inspectedPicture} src={`${process.env.REACT_APP_IMAGE_SERVER}/api/static/${inspectedPicture}`} alt='' />
              <div className={style.closeInspectedPictureButton} onClick={() => setDisplayInspectedPicture(false)}><span><FontAwesomeIcon style={{ transform: 'rotate(45deg)', color: 'black' }} icon={faPlus} /></span></div>
            </div>
          </ModalWrapper>
        ) : (
          null
        )
      }
      <div className={style.wrapper}>
        <Header
          whenSelectingWeek={whenSelectingWeek}
          whenSelectingCompany={whenSelectingCompany}
          whenSelectingVenue={whenSelectingVenue}
          selectedCompany={selectedCompany}
          selectedWeek={selectedWeek}
          selectedVenue={selectedVenue}
          venues={venues}
          whenPrettyNames={whenPrettyNames}
          autoSelectFirstVenue={true}
        />
        <span style={{position: 'absolute', right: '150px', top: '85px'}} >{seleniumStateString}<br/>{seleniumStateString2}</span>
        <button className={style.refreshButton} onClick={() => refresh()}>Refresh</button>
        {
          report ? (
            <div className={style.pageContent}>
              {
                reportByVenue.length > 0 && deltas !== undefined ? (
                  <div className={style.companyResume}>
                    <table className={style.companyResumeTable}>
                      <tbody>
                        <tr>
                          <th>Store</th>
                          <th>QA Time</th>
                          <th>Status</th>
                          <th>LR Score</th>
                          <th>∆ vs LR</th>
                        </tr>
                        {
                          reportByVenue.map((report, index) => {
                            return <tr key={index} className={`${report.venue === selectedStore ? style.highlightRow : ''}`}>
                              <td style={{ textAlign: 'left' }}>
                                <span style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10px' }}>
                                  <span style={{
                                    cursor: 'pointer',
                                  }} onClick={() => whenSelectingVenue(undefined, [{ value: report.venue }])}>{`${venuesPrettyName ? venuesPrettyName[report.venue] || report.doc.venue : report.doc.venue}`}</span>
                                  <div>
                                    {
                                      ['generated', 'delivered'].includes(report.state) ? (
                                        <div style={{display: 'inline-block'}}>
                                          <button className={style.downloadReportButton} onClick={() => window.open(`/pizza-tv-validation/venue/${report.venue}/week/${selectedWeek.getUTCDate()}-${selectedWeek.getUTCMonth() + 1}-${selectedWeek.getUTCFullYear()}`, '_blank')}><FontAwesomeIcon icon={faEye} /></button>
                                          <button style={{marginLeft: '10px'}} className={style.downloadReportButton} onClick={() => reportPreview(report)}><FontAwesomeIcon icon={faDownload} /></button>
                                        </div>
                                      ) : !['non-existent', 'waiting_initialization'].includes(report.state) ? (
                                        <button className={style.downloadReportButton} onClick={() => window.open(`/pizza-tv-validation/venue/${report.venue}/week/${selectedWeek.getUTCDate()}-${selectedWeek.getUTCMonth() + 1}-${selectedWeek.getUTCFullYear()}`, '_blank')}><FontAwesomeIcon icon={faEye} /></button>
                                      ) : (
                                        null
                                      )
                                    }
                                    {
                                      seleniumQueue.includes(report._id) ? (
                                        <span style={{border: '1px solid black', borderRadius: '5px', marginLeft: '10px'}} >SQ</span>
                                      ) : (
                                        null
                                      )
                                    }
                                  </div>
                                </span>
                              </td>
                              <td>
                              {
                                report.state === 'non-existent' ? (
                                  <div className={style.square} onClick={() => initializeReport(report, index)} style={{ cursor: 'pointer' }}>
                                    {report.qa_time ? getDiffHours(report.qa_time) : '--:--'}
                                  </div>
                                ) : (
                                  <div className={style.square}>
                                    {report.qa_time ? getDiffHours(report.qa_time) : '--:--'}
                                  </div>
                                )
                              }
                              </td>
                              <td>
                                <div className={style.square} style={getStateConf(report.state).style}>
                                  {getStateConf(report.state).symbol}
                                </div>
                              </td>
                              {
                                deltas[report.venue] ? (
                                  <React.Fragment>
                                    <td>
                                      <div className={style.square}>
                                        {deltas[report.venue].lrScore}
                                      </div>
                                    </td>
                                    <td>
                                      <div className={`${style.square} ${deltas[report.venue].dStyle}`}>
                                        {deltas[report.venue].delta}
                                      </div>
                                    </td>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <td>--</td>
                                    <td>--</td>
                                  </React.Fragment>
                                )
                              }
                            </tr>
                          })
                        }
                        <tr>
                          <td style={{ textAlign: 'left' }}>{`Total ${selectedCompanyPrettyName}`}</td>
                          <td></td>
                          <td></td>
                          <td><div className={style.square}>{`${lrAverageScore}%`}</div></td>
                          <td>
                            <div className={`${style.square} ${lrAverageDelta > 0 ? style.deltaPositive : lrAverageDelta < 0 ? style.deltaNegative : style.deltaZero}`}>
                              {`${lrAverageDelta > 0 ? '+' : lrAverageDelta < 0 ? '-' : ''}${Math.abs(lrAverageDelta)}%`}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}><button className={style.sendAllButton} onClick={() => sendAll()}>Send All</button></td>
                          <td colSpan={3}><button className={style.sendAllButton} onClick={() => initializeReports()}>QA done All</button></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  null
                )
              }
              {
                report && report.standard && reportSpecialties && oldReportsToStandard ? (
                  <div className={style.storeResume}>
                    {
                      report && report.scoreless_report === 1 ? (
                        null
                      ) : (
                        <div className={style.firstRow}>
                          <table className={style.storeResumeTable}>
                            <tbody>
                              <tr>
                                <th>Store Evolution</th>
                                <th>Pizza Count</th>
                                <th>Kwali Result</th>
                                <th>Stress Test - Delta bad pizzas</th>
                              </tr>
                              {
                                reportSpecialties.map((specialty, index) => {
                                  return <tr key={index}>
                                    <td>
                                      <div style={{ width: '100%' }}>
                                        <Plot
                                          data={[
                                            {
                                              type: 'bar',
                                              x: oldReportsToStandard.map((report) => report.key),
                                              y: oldReportsToStandard.map((report) => report[specialty]),
                                              textposition: 'outside',
                                              text: oldReportsToStandard.map((report) => `<span style="font-weight: bold">${(((report[specialty]) || 0) * 100).toFixed(0)}%</span>`),
                                              name: `${specialty}`,
                                              marker: {
                                                color: 'white',
                                                line: {
                                                  color: 'black',
                                                  width: 2
                                                }
                                              },
                                              textfont: {
                                                size: 15,
                                              }
                                            },
                                            {
                                              type: 'bar',
                                              x: [`W${report.doc.week}`],
                                              y: [report.standard[specialty]],
                                              textposition: 'outside',
                                              text: [`<span style="font-weight: bold">${`${(report.standard[specialty] * 100).toFixed(0)}%`}</span>`],
                                              name: `${specialty}`,
                                              marker: {
                                                color: '#4472c4',
                                                line: {
                                                  color: 'black',
                                                  width: 2
                                                }
                                              },
                                              textfont: {
                                                size: 15,
                                              }
                                            },
                                          ]}
                                          layout={{
                                            height: 200,
                                            autosize: true,
                                            margin: {
                                              t: 40,
                                              l: 40,
                                              r: 40,
                                              b: 40
                                            },
                                            yaxis: {
                                              title: { text: specialty },
                                              automargin: true,
                                              tickformat: ',.0%',
                                              showline: false,
                                              showticklabels: true,
                                              showgrid: false,
                                              zeroline: true,
                                              range: [0, 1.05],
                                              rangemode: 'tozero',
                                              tickfont: {
                                                size: 10
                                              }
                                            },
                                            xaxis: {
                                              showgrid: false,
                                              tickfont: {
                                                size: 10
                                              }
                                            },
                                            paper_bgcolor: 'rgba(255,255,255,1)',
                                            plot_bgcolor: 'rgba(255,255,255,1)',
                                            showlegend: false,

                                          }}
                                          config={{ displayModeBar: false }}
                                          useResizeHandler={true}
                                          style={{ width: "100%", height: "100%" }}
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <table className={style.pizzaCountTable}>
                                        <tbody>
                                          <tr>
                                            <td><FontAwesomeIcon style={{ color: '#00b050' }} icon={faCheck} /></td>
                                            <td>
                                              <div className={style.square}>
                                                {report.pizzas.filter(p => p.specialty === specialty && p.standard === 'to_standard').length}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td><FontAwesomeIcon style={{ color: '#ffd43b' }} icon={faQuestion} /></td>
                                            <td>
                                              <div className={style.square}
                                                style={{
                                                  backgroundColor: `${report.pizzas.filter(p => p.specialty === specialty && p.standard === 'maybe_to_standard').length / report.pizzas.filter(p => p.specialty === specialty).length >= 0.4 ? '#fff2cc' : 'white'}`
                                                }}>
                                                {report.pizzas.filter(p => p.specialty === specialty && p.standard === 'maybe_to_standard').length}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td><FontAwesomeIcon style={{ transform: 'rotate(45deg)', color: 'red' }} icon={faPlus} /></td>
                                            <td>
                                              <div className={style.square}>
                                                {report.pizzas.filter(p => p.specialty === specialty && p.standard === 'not_to_standard').length}
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>T</td>
                                            <td>
                                              <div className={style.square}
                                                style={{
                                                  backgroundColor: `${report.pizzas.filter(p => p.specialty === specialty).length <= 30 ? '#fe9999' : 'white'}`
                                                }}>
                                                {report.pizzas.filter(p => p.specialty === specialty).length}
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td>
                                      <table className={style.simulatorTable}>
                                        <tbody>
                                          <tr>
                                            <th># Pizzas</th>
                                            <th>Bad</th>
                                            <th>Good</th>
                                            <th>Score</th>
                                            <th>∆ VS LR</th>
                                          </tr>
                                          {
                                            simulatorData[specialty] ? (
                                              <tr>
                                                <td>
                                                  {simulatorData[specialty].count}
                                                </td>
                                                <td>
                                                  <input type='text' value={simulatorData[specialty].bads} onClick={e => e.target.select()} onChange={e => {
                                                    let value = e.target.value;
                                                    value = value.replace(/[^0-9]/g, '');
                                                    updateSimData(specialty, Number(value));
                                                  }} />
                                                </td>
                                                <td>
                                                  {simulatorData[specialty].goods}
                                                </td>
                                                <td>
                                                  {simulatorData[specialty].score}%
                                                </td>
                                                <td className={`${!(typeof simulatorData[specialty].delta === 'number') || simulatorData[specialty].delta === 0 ? '' : simulatorData[specialty].delta > 0 ? style.deltaPositive : style.deltaNegative}`}>
                                                  {simulatorData[specialty].delta}%
                                                </td>
                                              </tr>
                                            ) : (
                                              <tr></tr>
                                            )
                                          }
                                        </tbody>
                                      </table>
                                    </td>
                                    <td>
                                      {
                                        stressTest ? (
                                          <table className={style.stressTestTable}>
                                            <tbody>
                                              <tr>
                                                <th>∆ to low</th>
                                                <th>Low</th>
                                                <th>∆ to up</th>
                                                <th>Up</th>
                                              </tr>
                                              <tr>
                                                <td>
                                                  {stressTest[specialty].d_info_s}
                                                </td>
                                                <td>
                                                  {stressTest[specialty].d_score_s}
                                                </td>
                                                <td>
                                                  {stressTest[specialty].i_info_s}
                                                </td>
                                                <td>
                                                  {stressTest[specialty].i_score_s}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        ) : (
                                          null
                                        )
                                      }
                                    </td>
                                  </tr>
                                })
                              }
                              <tr>
                                <td>
                                  <div style={{ width: '100%' }}>
                                    <Plot
                                      data={[
                                        {
                                          type: 'bar',
                                          x: oldReportsToStandard.map((report) => report.key),
                                          y: oldReportsToStandard.map((report) => report.total),
                                          textposition: 'outside',
                                          text: oldReportsToStandard.map((report) => `<span style="font-weight: bold">${(((report.total) || 0) * 100).toFixed(0)}%</span>`),
                                          name: 'Total Production',
                                          marker: {
                                            color: 'white',
                                            line: {
                                              color: 'black',
                                              width: 2
                                            }
                                          },
                                          textfont: {
                                            size: 15,
                                          }
                                        },
                                        {
                                          type: 'bar',
                                          x: [`W${report.doc.week}`],
                                          y: [report.standard.total],
                                          textposition: 'outside',
                                          text: [`<span style="font-weight: bold">${`${(report.standard.total * 100).toFixed(0)}%`}</span>`],
                                          name: 'Total Production',
                                          marker: {
                                            color: '#4472c4',
                                            line: {
                                              color: 'black',
                                              width: 2
                                            }
                                          },
                                          textfont: {
                                            size: 15,
                                          }
                                        },
                                      ]}
                                      layout={{
                                        height: 200,
                                        autosize: true,
                                        margin: {
                                          t: 40,
                                          l: 40,
                                          r: 40,
                                          b: 40
                                        },
                                        yaxis: {
                                          title: { text: 'Total' },
                                          automargin: true,
                                          tickformat: ',.0%',
                                          showline: false,
                                          showticklabels: true,
                                          showgrid: false,
                                          zeroline: true,
                                          range: [0, 1.05],
                                          rangemode: 'tozero',
                                          tickfont: {
                                            size: 10
                                          }
                                        },
                                        xaxis: {
                                          showgrid: false,
                                          tickfont: {
                                            size: 10
                                          }
                                        },
                                        paper_bgcolor: 'rgba(255,255,255,1)',
                                        plot_bgcolor: 'rgba(255,255,255,1)',
                                        showlegend: false,

                                      }}
                                      config={{ displayModeBar: false }}
                                      useResizeHandler={true}
                                      style={{ width: "100%", height: "100%" }}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <table className={style.pizzaCountTable}>
                                    <tbody>
                                      <tr>
                                        <td><FontAwesomeIcon style={{ color: '#00b050' }} icon={faCheck} /></td>
                                        <td>
                                          <div className={style.square}>
                                            {report.pizzas.filter(p => p.standard === 'to_standard').length}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td><FontAwesomeIcon style={{ color: '#ffd43b' }} icon={faQuestion} /></td>
                                        <td>
                                          <div className={style.square}
                                            style={{
                                              backgroundColor: `${report.pizzas.filter(p => p.standard === 'maybe_to_standard').length / report.pizzas.length >= 0.4 ? '#fff2cc' : 'white'}`
                                            }}>
                                            {report.pizzas.filter(p => p.standard === 'maybe_to_standard').length}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td><FontAwesomeIcon style={{ transform: 'rotate(45deg)', color: 'red' }} icon={faPlus} /></td>
                                        <td>
                                          <div className={style.square}>
                                            {report.pizzas.filter(p => p.standard === 'not_to_standard').length}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>T</td>
                                        <td>
                                          <div className={style.square}>
                                            {report.pizzas.length}
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                                <td>
                                  {
                                    simulatorData.total ? (
                                      <table className={style.simulatorTable}>
                                        <tbody>
                                          <tr>
                                            <th># Pizzas</th>
                                            <th>Bad</th>
                                            <th>Good</th>
                                            <th>Score</th>
                                            <th>∆ VS LR</th>
                                          </tr>
                                          <tr>
                                            <td>
                                              {simulatorData.total.count}
                                            </td>
                                            <td>
                                              {simulatorData.total.bads}
                                            </td>
                                            <td>
                                              {simulatorData.total.goods}
                                            </td>
                                            <td>
                                              {simulatorData.total.score}%
                                            </td>
                                            <td className={`${!(typeof simulatorData.total.delta === 'number') || simulatorData.total.delta === 0 ? '' : simulatorData.total.delta > 0 ? style.deltaPositive : style.deltaNegative}`}>
                                              {simulatorData.total.delta}%
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    ) : (
                                      null
                                    )
                                  }
                                </td>
                                <td>
                                  {
                                    (stressTest && stressTest._total_) ? (
                                      <table className={style.stressTestTable}>
                                        <tbody>
                                          <tr>
                                            <th>∆ to low</th>
                                            <th>Low</th>
                                            <th>∆ to up</th>
                                            <th>Up</th>
                                          </tr>
                                          <tr>
                                            <td>
                                              {stressTest._total_.d_info_s}
                                            </td>
                                            <td>
                                              {stressTest._total_.d_score_s}
                                            </td>
                                            <td>
                                              {stressTest._total_.i_info_s}
                                            </td>
                                            <td>
                                              {stressTest._total_.i_score_s}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    ) : (
                                      null
                                    )
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )
                    }
                    {
                      report && report.scoreless_report === 1 ? (
                        null
                      ) : (
                        <div className={style.secondRow}>
                          {
                            chartsXAxis.length > 0 && storesEvolution ? (
                              <React.Fragment>
                                <div style={{ paddingLeft: 'calc(20% - 95px)' }}>
                                  <Plot
                                    data={
                                      (storesEvolutionOrder ?? []).map((storeName, index) => {
                                        return {
                                          type: 'scatter',
                                          x: chartsXAxis.map(key => key.split(' ')[0]),
                                          y: getYValues(storesEvolution[storeName] ? storesEvolution[storeName].reports : []),
                                          text: getYValues(storesEvolution[storeName] ? storesEvolution[storeName].reports : []).map((toStandardPercentage) => `<span style="font-weight: bold">${(((toStandardPercentage) || 0) * 100).toFixed(0)}%</span>`),
                                          name: storesEvolution[storeName] ? storesEvolution[storeName].pretty_name : '',
                                          mode: 'markers',
                                          marker: {
                                            symbol: SYMBOLS[index % SYMBOLS.length],
                                            size: 10,
                                            color: COLORS[index % COLORS.length],
                                            line: {
                                              color: COLORS[index % COLORS.length],
                                              width: 2
                                            }
                                          },
                                          textfont: {
                                            size: 25,
                                          }
                                        }
                                      }).concat([{
                                        type: 'scatter',
                                        x: chartsXAxis.map(key => key.split(' ')[0]),
                                        y: (storesEvolutionWeekAvg ?? []),
                                        text: (storesEvolutionWeekAvg ?? []).map((toStandardPercentage) => `<span style="font-weight: bold">${(((toStandardPercentage) || 0) * 100).toFixed(0)}%</span>`),
                                        name: 'Weekly Average',
                                        mode: 'markers',
                                        marker: {
                                          size: 3,
                                          color: 'black',
                                        },
                                        textfont: {
                                          size: 25,
                                        }
                                      }]).concat(storesEvolutionTrace(storesEvolution, chartsXAxis, (storesEvolutionWeekAvg ?? []), (storesEvolutionOrder ?? [])))
                                    }
                                    layout={{
                                      height: 1400,
                                      autosize: true,
                                      margin: {
                                        t: 80,
                                        l: 80,
                                        r: 0,
                                        b: 40
                                      },
                                      yaxis: {
                                        automargin: true,
                                        tickformat: ',.0%',
                                        showline: false,
                                        showticklabels: true,
                                        showgrid: false,
                                        zeroline: true,
                                        range: [0, 1.05],
                                        rangemode: 'tozero',
                                        tickfont: {
                                          size: 25
                                        }
                                      },
                                      xaxis: {
                                        showgrid: false,
                                        tickfont: {
                                          size: 25
                                        }
                                      },
                                      paper_bgcolor: 'rgba(255,255,255,1)',
                                      plot_bgcolor: 'rgba(255,255,255,1)',
                                      showlegend: false,

                                    }}
                                    config={{ displayModeBar: false }}
                                    useResizeHandler={true}
                                    style={{ width: "100%" }}
                                  />
                                </div>
                                <div style={{ paddingRight: '10px' }}>
                                  <table className={style.storesEvolutionTable}>
                                    <tbody>
                                      <tr>
                                        <td> <div style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          gap: '5px'
                                        }}><span style={{
                                          background: 'linear-gradient(to right, black 0px, black 25%, white 25%, white 37.5%, black 37.5%, black 62.5%, white 62.5%, white 75%, black 75%, black 100%)',
                                          width: '45px',
                                          height: '5px',
                                          display: 'block',
                                        }}></span>Weekly Average</div></td>
                                        {
                                          (storesEvolutionWeekAvg ?? []).map((val, index) => {
                                            return <td key={index}>{val !== undefined ? `${(val * 100).toFixed(0)}%` : ''}</td>
                                          })
                                        }
                                      </tr>
                                      {
                                        (storesEvolutionOrder ?? []).map((storeName, index) => {
                                          return <tr key={index}>
                                            <td> <div style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              gap: '5px',
                                              fontWeight: `${storeName === report.venue ? 'bold' : 'normal'}`
                                            }}><Plot
                                                data={[
                                                  {
                                                    x: [0],
                                                    y: [0],
                                                    mode: 'markers',
                                                    marker: {
                                                      symbol: SYMBOLS[index % SYMBOLS.length],
                                                      size: 10,
                                                      color: COLORS[index % COLORS.length],
                                                      line: {
                                                        width: 2,
                                                        color: COLORS[index % COLORS.length],
                                                      },
                                                    },
                                                  }
                                                ]}
                                                layout={{
                                                  width: 20,
                                                  height: 20,
                                                  showlegend: false,
                                                  xaxis: {
                                                    showgrid: false,
                                                    zeroline: false,
                                                    showticklabels: false
                                                  },
                                                  yaxis: {
                                                    showgrid: false,
                                                    zeroline: false,
                                                    showticklabels: false
                                                  },
                                                  margin: { l: 0, r: 0, t: 0, b: 0 },
                                                }}
                                                config={{ displayModeBar: false }}
                                              /><span style={{
                                                backgroundColor: COLORS[index % COLORS.length],
                                                width: '40px',
                                                height: '5px',
                                                display: 'block',
                                                marginLeft: '-20px'
                                              }}></span>{storesEvolution[storeName] ? storesEvolution[storeName].pretty_name : ''}</div></td>
                                            {
                                              getYValues(storesEvolution[storeName] ? storesEvolution[storeName].reports : []).map((val, index2) => {
                                                return <td key={index2}>{val !== undefined ? `${(val * 100).toFixed(0)}%` : ''}</td>
                                              })
                                            }
                                          </tr>
                                        })
                                      }
                                    </tbody>
                                  </table>
                                </div>

                              </React.Fragment>
                            ) : (
                              null
                            )
                          }
                        </div>
                      )
                    }
                    {
                      report && report.scoreless_report === 1 ? (
                        null
                      ) : (
                        <div className={style.secondRow}>
                          <div className={style.features}>
                            {
                              featureBest ? (
                                <div className={style.feature}>
                                  <img onClick={() => { setInspectedPicture(featureBest.img_url); setDisplayInspectedPicture(true) }} src={`${process.env.REACT_APP_IMAGE_SERVER}/api/static/${featureBest.img_url}`} alt='Best Pizza' />
                                  <span className={style.cardID}>{featureBest.cardIndex}</span>
                                  <div className={style.featureInfo}>
                                    <div className={style.featureLegend}>{`${featureBest.weekday.slice(0, 3)} - ${featureBest.time_stamp || (featureBest.shift === 'AM' ? 'Lunch' : 'Dinner')}`}</div>
                                    <img src={check} alt='best' className={style.featureIcon} />
                                  </div>
                                </div>

                              ) : (
                                null
                              )
                            }
                            {
                              featureWorst ? (
                                <div className={style.feature}>
                                  <img onClick={() => { setInspectedPicture(featureWorst.img_url); setDisplayInspectedPicture(true) }} src={`${process.env.REACT_APP_IMAGE_SERVER}/api/static/${featureWorst.img_url}`} alt='Worst Pizza' />
                                  <span className={style.cardID}>{featureWorst.cardIndex}</span>
                                  <div className={style.featureInfo}>
                                    <div className={style.featureLegend}>{`${featureWorst.weekday.slice(0, 3)} - ${featureWorst.time_stamp || (featureWorst.shift === 'AM' ? 'Lunch' : 'Dinner')}`}</div>
                                    <img src={mark} alt='worst' className={style.featureIcon} />
                                  </div>
                                </div>
                              ) : (
                                null
                              )
                            }
                          </div>
                          <div className={style.cards}>
                            {
                              cards.map((card, index) => {
                                return (
                                  <div className={style.card} key={index}>
                                    <img onClick={() => { setInspectedPicture(card.img_url); setDisplayInspectedPicture(true) }} src={`${process.env.REACT_APP_IMAGE_SERVER}/api/static/${card.img_url}`} alt='Pizza' />
                                    <span className={style.cardID}>{card.cardIndex}</span>
                                    <div><button style={{
                                      position: 'absolute',
                                      top: '0',
                                      right: '40px',
                                      background: 'white',
                                      border: 'none',
                                      borderRadius: '10px',
                                      margin: '5px',
                                      fontSize: '25px'
                                    }} onClick={() => showPizzaCandidates(card, 1)} ><FontAwesomeIcon style={{ color: 'black' }} icon={faRecycle} /></button></div>
                                    <div><button style={{
                                      position: 'absolute',
                                      top: '0',
                                      left: '0',
                                      background: 'none',
                                      border: 'none',
                                      borderRadius: '10px',
                                      margin: '5px',
                                      fontSize: '25px'
                                    }} onClick={() => modifyPizzaCard(card)} ><FontAwesomeIcon style={{ color: !card.featured ? 'white' : card.standard === 'to_standard' ? '#0f9900' : '#cb0001' }} icon={faCrown} /></button></div>
                                    <div className={style.cardInfo}>
                                      <div className={style.cardLegend}>{`${card.weekday.slice(0, 3)} - ${card.timeStamp || (card.shift === 'AM' ? 'Lunch' : 'Dinner')}`}</div>
                                      {
                                        report.scoreless_report !== 1 ? (
                                          <img src={card.standard === 'to_standard' ? check : mark} alt='Pizza' className={style.cardIcon} />
                                        ) : (
                                          null
                                        )
                                      }
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      )
                    }
                    <div className={style.secondRow}>
                      {
                        Object.keys(cardsScoreless).length > 0 ? (
                          Object.keys(cardsScoreless).sort((a, b) => DAYS.indexOf(a) - DAYS.indexOf(b)).map((day, index1) => {
                            return (
                              <div key={index1}>
                                {
                                  Object.keys(cardsScoreless[day]).sort((a, b) => SHIFTS.indexOf(a) - SHIFTS.indexOf(b)).map((shift, index2) => {
                                    return (
                                      <div key={index2}>
                                        <span style={{
                                          border: shift === 'PM' ? '3px solid #4472c4' : '3px solid #f6c161',
                                          borderRadius: '50px',
                                          padding: '10px 0',
                                          fontSize: '20px',
                                          textAlign: 'center',
                                          marginBottom: '15px',
                                          marginTop: '15px',
                                          display: 'block',
                                          width: '300px'
                                        }}>{`${day} - ${shift}`}</span>
                                        <div className={style.shiftedCards}>
                                          {
                                            cardsScoreless[day][shift].map((card, index3) => {
                                              return (
                                                <div className={style.card} key={index3}>
                                                  <img onClick={() => { setInspectedPicture(card.img_url); setDisplayInspectedPicture(true) }} src={`${process.env.REACT_APP_IMAGE_SERVER}/api/static/${card.img_url}`} alt='Pizza' />
                                                  <div><button style={{
                                                    position: 'absolute',
                                                    top: '0',
                                                    right: '40px',
                                                    background: 'white',
                                                    border: 'none',
                                                    borderRadius: '10px',
                                                    margin: '5px',
                                                    fontSize: '25px'
                                                  }} onClick={() => showPizzaCandidates(card, report.scoreless_report ? 2 : 3)} ><FontAwesomeIcon style={{ color: 'black' }} icon={faRecycle} /></button></div>
                                                  <span className={style.cardID}>{card.cardIndex}</span>
                                                  <div className={style.cardInfo}>
                                                    <div className={style.cardLegend}>{`${card.weekday.slice(0, 3)} - ${card.timeStamp || (card.shift === 'AM' ? 'Lunch' : 'Dinner')}`}</div>
                                                  </div>
                                                </div>
                                              )
                                            })
                                          }
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            )
                          })
                        ) : (
                          null
                        )
                      }
                    </div>
                  </div>
                ) : (
                  null
                )
              }
            </div>
          ) : (
            null
          )
        }
      </div>
    </LoadingScreen>
  )
}

export default ReportControl;